import { InputText } from '@/components/form/inputs/InputText';
import { createComponent } from '@/lib/vue';
import { IDataTableHeader, IFormInputText } from '@/typings';
import { PropType } from 'vue';

interface IProps {
  value?: string | null;
  input: IFormInputText;
  header: IDataTableHeader;
}

interface IEvents {
  onInput: (value: string | null) => void;
}

export const TdInputText = createComponent<IProps, IEvents>({
  name: 'TdInputText',
  props: {
    value: String,
    input: { type: Object as PropType<IFormInputText>, required: true },
    header: { type: Object as PropType<IDataTableHeader>, required: true },
  },
  setup(props, ctx) {
    function emitInput(value: string | null) {
      ctx.emit('input', value);
    }

    return () => (
      <div
        key={props.header.value}
        style={{ width: `${props.header.width || 200}px` }}
      >
        <InputText
          input={props.input}
          value={props.value}
          onInput={emitInput}
        />
      </div>
    );
  },
});
