import { IndexDataTable } from '@/components/datatable/IndexDataTable';
import { useFormPage } from '@/lib/composables/form/useFormPage';
import { ConstantsHelper } from '@/lib/constants/helper';
import { getEditValue } from '@/lib/form';
import { DialogHelpers } from '@/lib/helpers/dialogs/dialog.helpers';
import { truncateStr } from '@/lib/helpers/utils';
import { uuid } from '@/lib/helpers/uuid';
import { createComponent } from '@/lib/vue';
import {
  IDataTableHeader,
  IFormPage,
  IIndexDataTableActions,
  IIndexDataTableButton,
  IProcedimentoDespesaFormModel,
  IRequireField,
} from '@/typings';
import { computed } from '@vue/composition-api';
import { ProcedimentoDespesaForm } from './ProcedimentoDespesaForm';

interface IJsxProps {
  // model
  value?: IProcedimentoDespesaFormModel[];
}

type IProps = IRequireField<IJsxProps, 'value'>;

interface IEvents {
  onInput: (despesas: IProcedimentoDespesaFormModel[]) => void;
}

export const ProcedimentoDespesas = createComponent<IJsxProps, IEvents>({
  name: 'ProcedimentoDespesas',
  props: {
    value: { type: Array, required: true },
  },
  setup(props: IProps, ctx) {
    function emitInput(despesas: IProcedimentoDespesaFormModel[]) {
      ctx.emit('input', despesas);
    }

    const { page, handleSubmit } = useFormPage<IProcedimentoDespesaFormModel>({
      ctx,
      async submitCallback(dialogModel) {
        if (!dialogModel.id) {
          dialogModel.id = uuid();
          dialogModel.new = true;

          emitInput([...props.value, dialogModel]);
        } else {
          emitInput([
            ...props.value.filter(v => v.id !== dialogModel.id),
            dialogModel,
          ]);
        }
      },
    });

    const { $items, headers } = useComputeds(props);

    const { btn, actions } = useActions({
      props,
      page,
      handleSubmit,
      emitInput,
    });

    return () => (
      <IndexDataTable
        title="Despesas"
        btn={btn}
        headers={headers}
        items={$items.value}
        actions={actions}
      />
    );
  },
});

interface IItem {
  id: string | undefined;
  tipo: string | undefined;
  unidade: string | undefined;
  tiss: string;
  valorUnitario: number | null;
}

function useComputeds(props: IProps) {
  const $items = computed<IItem[]>(() => {
    return props.value.map(v => {
      const tipo = ConstantsHelper.tiss.despesaTipos.find(
        f => f.value === v.despesa.tipo,
      );

      const unidade = ConstantsHelper.tiss.unidadeMedida.find(
        f => f.value === v.despesa.unidade,
      );

      return {
        id: v.id,
        tipo: tipo?.label,
        unidade: unidade?.label,
        tiss: truncateStr(v.tiss.nome || '', 60),
        valorUnitario: v.despesa.valorUnitario,
      };
    });
  });

  const headers: IDataTableHeader<IItem>[] = [
    {
      text: 'Nome',
      value: 'nome',
      mapValue: v => v.tiss,
    },
    {
      text: 'Tipo',
      value: 'tipo',
      mapValue: v => v.tipo,
    },
    {
      text: 'Unidade',
      value: 'unidade',
      mapValue: v => v.unidade,
      valueAlign: 'center',
    },
    {
      text: 'Valor Unitário',
      value: 'valorUnitario',
      mapValue: v => v.valorUnitario,
      align: 'end',
      valueAlign: 'end',
      money: true,
    },
  ];

  return { $items, headers };
}

function useActions({
  props,
  page,
  handleSubmit,
  emitInput,
}: {
  props: IProps;
  page: IFormPage;
  handleSubmit: (model: IProcedimentoDespesaFormModel) => Promise<void>;
  emitInput: (despesas: IProcedimentoDespesaFormModel[]) => void;
}) {
  function handleNew() {
    return showDialog({
      title: 'Nova despesa',
      initialValue: null,
    });
  }

  function handleEdit(id?: string | null) {
    const initialValue = getEditValue(id, props.value);

    if (!initialValue) return handleNew();

    return showDialog({
      title: 'Editar despesa',
      initialValue,
    });
  }

  function showDialog({
    title,
    initialValue,
  }: {
    title: string;
    initialValue: IProcedimentoDespesaFormModel | null;
  }) {
    return DialogHelpers.system.form({
      title,
      form: ProcedimentoDespesaForm,
      page,
      initialValue,
      onSubmit: handleSubmit,
    });
  }

  function handleRemove(id: string) {
    emitInput(props.value.filter(f => f.id !== id));
  }

  const actions: IIndexDataTableActions = {
    edit: handleEdit,
    remove: handleRemove,
  };

  const btn: IIndexDataTableButton = {
    text: 'Nova Despesa',
    outlined: true,
    onClick: handleNew,
  };

  return { actions, btn };
}
