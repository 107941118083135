import { MyPage } from '@/components/page/MyPage';
import { createComponent } from '@/lib/vue';

export const AlertasEmailAniversariantesPage = createComponent({
  name: 'AlertasEmailAniversariantesPage',
  setup(props, ctx) {
    return () => <MyPage title="Email de Aniversariantes" />;
  },
});

export default AlertasEmailAniversariantesPage;
