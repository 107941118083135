import { MyPage } from '@/components/page/MyPage';
import { createComponent } from '@/lib/vue';

export const AlertasSmsPage = createComponent({
  name: 'AlertasSmsPage',
  setup(props, ctx) {
    return () => <MyPage title="SMS" />;
  },
});

export default AlertasSmsPage;
