import { useFormPage } from '@/lib/composables/form/useFormPage';
import { useConfiguracaoProfissionalPage } from '@/lib/composables/page/useConfiguracaoProfissionalPage';
import { useNome } from '@/lib/composables/utils/useNome';
import { PrescricaoModeloService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { IPrescricaoModeloFormModel } from '@/typings';
import { computed, SetupContext } from '@vue/composition-api';
import { ConfiguracaoProfissionalPage } from '../../../components/ConfiguracaoProfissionalPage';
import { PrescricaoModeloForm } from '../../components/prescricaoModelo/PrescricaoModeloForm';

export default createComponent({
  name: 'PrescricaoModeloFormPage',
  setup(props, ctx) {
    const { profissionalId } = useConfiguracaoProfissionalPage();

    const { $title, page, handleSubmit, setNome } = useComputeds({
      ctx,
      profissionalId,
    });

    const { handleDelete } = useEvents(profissionalId);

    return () => (
      <ConfiguracaoProfissionalPage title={$title.value} form>
        <PrescricaoModeloForm
          page={page}
          onSubmit={handleSubmit}
          onDelete={handleDelete}
          onNomeChanged={setNome}
        />
      </ConfiguracaoProfissionalPage>
    );
  },
});

function useComputeds({
  ctx,
  profissionalId,
}: {
  ctx: SetupContext;
  profissionalId: string;
}) {
  const { page, handleSubmit, isEdit } =
    useFormPage<IPrescricaoModeloFormModel>({
      ctx,
      async submitCallback(model) {
        if (page.id) {
          return PrescricaoModeloService.update({
            id: page.id,
            profissionalId,
            model,
          });
        } else {
          return PrescricaoModeloService.create({
            profissionalId,
            model,
          });
        }
      },
    });

  const { $nome, setNome } = useNome({ ctx });

  const $title = computed(() => {
    if ($nome.value) return `Prescrição: ${$nome.value}`;

    return isEdit ? 'Editar Prescrição' : 'Nova Prescrição';
  });

  return {
    page,
    handleSubmit,
    isEdit,
    $nome,
    setNome,
    $title,
  };
}

function useEvents(profissionalId: string) {
  async function handleDelete(id: string) {
    return PrescricaoModeloService.delete({
      id,
      profissionalId,
    });
  }

  return { handleDelete };
}
