import { ProntuarioSecaoFieldType } from '@/typings';

export interface IProntuarioSecaoInputTypeOption {
  title: string;
  img: string;
  type: ProntuarioSecaoFieldType;
}

export const prontuarioSecaoInputTypes: IProntuarioSecaoInputTypeOption[] = [
  {
    title: 'Texto curto',
    img: getImgPath('TextoCurto'),
    type: ProntuarioSecaoFieldType.TEXT,
  },
  {
    title: 'Texto longo',
    img: getImgPath('TextoLongo'),
    type: ProntuarioSecaoFieldType.RICH_TEXT,
  },
  {
    title: 'Numérico',
    img: getImgPath('Numerico'),
    type: ProntuarioSecaoFieldType.NUMBER,
  },
  {
    title: 'Verdadeiro ou falso',
    img: getImgPath('VerdadeiroFalso'),
    type: ProntuarioSecaoFieldType.CHECKBOX,
  },
  {
    title: 'Múltipla escolha',
    img: getImgPath('MultiplaEscolha'),
    type: ProntuarioSecaoFieldType.RADIO,
  },
  {
    title: 'Grupo de opções',
    img: getImgPath('GrupoOpcoes'),
    type: ProntuarioSecaoFieldType.GROUP_CHECKBOX,
  },
  {
    title: 'Lista',
    img: getImgPath('Lista'),
    type: ProntuarioSecaoFieldType.SELECT,
  },
  {
    title: 'Data',
    img: getImgPath('Data'),
    type: ProntuarioSecaoFieldType.DATE,
  },
  {
    title: 'Hora',
    img: getImgPath('Hora'),
    type: ProntuarioSecaoFieldType.TIME,
  },
];

function getImgPath(imgName: string) {
  return `/img/inputs/${imgName}.png`;
}
