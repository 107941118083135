import { MyPage } from '@/components/page/MyPage';
import { createComponent } from '@/lib/vue';
import { ConfiguracaoSelectProfissional } from './configuracaoProfissionalPage/ConfiguracaoSelectProfissional';

export const ConfiguracaoProfissionalPage = createComponent({
  name: 'ConfiguracaoProfissionalPage',
  props: {
    title: { type: String, required: true },
    select: { type: Boolean, default: false },
    form: { type: Boolean, default: false },
  },
  setup(props, ctx) {
    return () => {
      const defaultSlot = ctx.slots.default?.();

      return (
        <div class="flex flex-col">
          <ConfiguracaoSelectProfissional
            select={props.select}
            form={props.form}
          />

          <MyPage title={props.title} form={props.form}>
            {defaultSlot}
          </MyPage>
        </div>
      );
    };
  },
});
