import { FormFields } from '@/components/form/fields/FormFields';
import { MyForm } from '@/components/form/MyForm';
import { PageSection } from '@/components/page/PageSection';
import { FormHeader } from '@/components/typography/FormHeader';
import {
  IDialogFormProps,
  IFormEvents,
  useDialogFormConfig,
} from '@/lib/composables/form/useFormConfig';
import { ConstantsHelper } from '@/lib/constants/helper';
import { LookupsConfigs } from '@/lib/form/lookups';
import { TissTussLookupService } from '@/lib/services/tiss/tissTussLookup.service';
import { createComponent } from '@/lib/vue';
import {
  IConvenioItem,
  IProcedimentoConvenioFormModel,
  IProcedimentoConvenioFormSchema,
  TissTabela,
} from '@/typings';
import { computed, SetupContext } from '@vue/composition-api';

interface IProps extends IDialogFormProps<IProcedimentoConvenioFormModel> {}

interface IEvents extends IFormEvents<IProcedimentoConvenioFormModel> {}

// TODO: create lookup dialog to select TISS
export const ProcedimentoConvenioForm = createComponent<IProps, IEvents>({
  name: 'ProcedimentoConvenioForm',
  props: {
    page: { type: Object, required: true },
    initialValue: { type: Object },
  },
  setup(props, ctx) {
    const { $form, $schema, emitSubmit, emitCancel } = useForm(props, ctx);

    return () => (
      <MyForm
        dialog
        form={$form.value}
        onSubmit={emitSubmit}
        onCancel={emitCancel}
      >
        <PageSection>
          <FormHeader title="Convênio" />

          <FormFields
            form={$form.value}
            schema={$schema.value.convenio}
            v-model={$form.value.model.convenio}
          />
        </PageSection>

        <PageSection>
          <FormHeader title="Tiss" />

          <v-btn onClick={handleOpenDialog}>Selecionar</v-btn>

          {/* <FormFields
            form={$form.value}
            schema={$schema.value.tiss}
            v-model={$form.value.model.tiss}
          /> */}
        </PageSection>
      </MyForm>
    );
  },
});

function useForm(props: IProps, ctx: SetupContext) {
  const defaultValue: IProcedimentoConvenioFormModel = {
    convenio: {
      convenioId: null,
      nome: null,
      valor: null,
      particular: false,
    },
    tiss: {
      tabela: null,
      codigo: null,
      nome: null,
    },
  };

  return useDialogFormConfig<
    IProcedimentoConvenioFormModel,
    IProcedimentoConvenioFormSchema
  >({
    page: props.page,
    ctx,
    initialValue: props.initialValue || defaultValue,
    mapSchema: (model, $form) => {
      const $convenio = computed<IConvenioItem | null>(() => {
        if (!model.convenio.convenioId) return null;

        return {
          id: model.convenio.convenioId,
          nome: model.convenio.nome!,
          particular: !!model.convenio.particular,
        };
      });
      const $procedimentoNome = computed(() => {
        switch (model.tiss.tabela) {
          case TissTabela.TB_20:
            return 'Medicamento';
          case TissTabela.TB_19:
            return 'Material';
          default:
            return 'Procedimento';
        }
      });

      return {
        convenio: {
          convenioId: {
            label: 'Convênio',
            type: 'autocomplete',
            itemLabel: 'nome',
            editValue: $convenio.value,
            lookup: LookupsConfigs.convenio({
              onModelChange(value) {
                $form.value.model.convenio.nome = value ? value.nome : null;

                $form.value.model.convenio.particular = !!value?.particular;
              },
            }),
            validations: {
              required: true,
            },
          },
          valor: {
            label: 'Valor',
            type: 'money',
            validations: {
              required: true,
            },
          },
        },
        tiss: {
          tabela: {
            label: 'Tabela',
            type: 'select',
            items: ConstantsHelper.tiss.tabelas,
            validations: {
              required: true,
            },
          },
          codigo: {
            label: $procedimentoNome.value,
            type: 'autocomplete',
            itemLabel: 'descricao',
            itemValue: 'codigo',
            lookup: LookupsConfigs.tissProcedimento({
              tabela: model.tiss.tabela,
              codigo: model.tiss.codigo,
              onModelChange(value) {
                $form.value.model.tiss.nome = value?.nome || null;
              },
            }),
            validations: {
              required: true,
            },
          },
        },
      };
    },
  });
}

function handleOpenDialog() {
  TissTussLookupService.showDialog({ title: 'Procedimentos' });
}
