import { MyForm } from '@/components/form/MyForm';
import { PageSection } from '@/components/page/PageSection';
import { Tabs } from '@/components/utils/Tabs';
import { useValue } from '@/lib/composables';
import {
  IFormEvents,
  IFormProps,
  useFormConfig,
} from '@/lib/composables/form/useFormConfig';
import { useRouteParams } from '@/lib/composables/utils/useRouter';
import { ConstantsHelper } from '@/lib/constants/helper';
import { LookupsConfigs } from '@/lib/form/lookups';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { ConfiguracaoAgendaService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import {
  IConfiguracaoAgendaFormModel,
  IConfiguracaoAgendaFormSchema,
  IForm,
  IFormValidationErrors,
  ITab,
} from '@/typings';
import { SetupContext } from '@vue/composition-api';
import { ConfiguracaoAgendaFormDiasAtendimentoFields as DiasAtendimentoFields } from './configuracaoAgendaForm/ConfiguracaoAgendaFormDiasAtendimentoFields';
import { ConfiguracaoAgendaFormDiasBloqueadosFields as DiasBloqueadosFields } from './configuracaoAgendaForm/ConfiguracaoAgendaFormDiasBloqueadosFields';
import { ConfiguracaoAgendaFormHorarioAlmocoFields as HorarioAlmocoFields } from './configuracaoAgendaForm/ConfiguracaoAgendaFormHorarioAlmocoFields';
import { ConfiguracaoAgendaFormHorarioAtendimentoFields as HorarioAtendimentoFields } from './configuracaoAgendaForm/ConfiguracaoAgendaFormHorarioAtendimentoFields';
import { ConfiguracaoAgendaFormPadroesFields as PadroesFields } from './configuracaoAgendaForm/ConfiguracaoAgendaFormPadroesFields';
import { ConfiguracaoAgendaFormTabCarencia as TabCarencia } from './configuracaoAgendaForm/ConfiguracaoAgendaFormTabCarencia';
import { ConfiguracaoAgendaFormTabOutrasInformacoes as TabOutrasInformacoes } from './configuracaoAgendaForm/ConfiguracaoAgendaFormTabOutrasInformacoes';

interface IProps extends IFormProps {}

interface IEvents extends IFormEvents<IConfiguracaoAgendaFormModel> {}

export const ConfiguracaoAgendaForm = createComponent<IProps, IEvents>({
  name: 'ConfiguracaoAgendaForm',
  props: {
    page: { type: Object, required: true },
  },
  setup(props, ctx) {
    const { profissionalId } = useRouteParams();
    const [$activeTab] = useValue(0);

    const tabs: ITab[] = [
      {
        id: 'carencia',
        text: 'Carência',
        icon: MyIcons.carencia,
      },
      {
        id: 'outras_informacoes',
        text: 'Outras informações',
        icon: MyIcons.outrasInformacoes,
      },
    ];

    const { $form, $schema, emitSubmit, $initialProcedimentos } = useForm({
      props,
      profissionalId,
      ctx,
    });

    return () => (
      <MyForm noCancel form={$form.value} onSubmit={emitSubmit}>
        <PadroesFields form={$form.value} schema={$schema.value} />

        <DiasBloqueadosFields form={$form.value} schema={$schema.value} />

        <DiasAtendimentoFields form={$form.value} schema={$schema.value} />

        <HorarioAtendimentoFields form={$form.value} schema={$schema.value} />

        <HorarioAlmocoFields form={$form.value} schema={$schema.value} />

        <PageSection divider>
          <Tabs items={tabs} v-model={$activeTab.value}>
            <TabCarencia
              slot="carencia"
              form={$form.value}
              schema={$schema.value}
              initialProcedimentos={$initialProcedimentos.value}
              profissionalId={profissionalId}
            />

            <TabOutrasInformacoes
              slot="outras_informacoes"
              form={$form.value}
              schema={$schema.value}
            />
          </Tabs>
        </PageSection>
      </MyForm>
    );
  },
});

export interface IConfiguracaoAgendaFormProps {
  form: IForm<IConfiguracaoAgendaFormModel>;
  schema: IConfiguracaoAgendaFormSchema;
}

function useForm({
  props,
  profissionalId,
  ctx,
}: {
  props: IProps;
  profissionalId: string;
  ctx: SetupContext;
}) {
  const [$initialProcedimentos, setInitialProcedimentos] = useValue<string[]>(
    [],
  );
  const { $form, $schema, emitSubmit } = useFormConfig<
    IConfiguracaoAgendaFormModel,
    IConfiguracaoAgendaFormSchema
  >({
    page: props.page,
    initialValue: {
      padroes: {
        visualizacaoPadrao: null,
        procedimentoPadraoId: null,
      },
      diasBloqueados: {
        mostrarDiasBloqueados: true,
      },
      diasAtendimento: {
        domingo: true,
        segunda: true,
        terca: true,
        quarta: true,
        quinta: true,
        sexta: true,
        sabado: true,
      },
      horarioAtendimento: {
        horaInicio: null,
        horaFim: null,
      },
      horarioAlmoco: {
        horarioAlmoco: true,
        horaAlmocoInicio: null,
        horaAlmocoFim: null,
      },
      carencia: {
        alertaPeriodoCarencia: true,
      },
      procedimentosCarenciasIds: [],
      outrasInformacoes: {
        observacao: null,
      },
    },
    mapSchema: model => ({
      padroes: {
        visualizacaoPadrao: {
          label: 'Visualização padrão',
          type: 'select',
          items: ConstantsHelper.configuracaoAgendaVisualizacaoPadrao,
          layout: { sm: 6 },
          validations: {
            required: true,
          },
        },
        procedimentoPadraoId: {
          label: 'Procedimento padrão',
          type: 'autocomplete',
          itemLabel: 'nome',
          lookup: LookupsConfigs.procedimento({
            profissionalId,
          }),
          layout: { sm: 6 },
          validations: {
            required: true,
          },
        },
      },
      diasBloqueados: {
        mostrarDiasBloqueados: {
          label: 'Mostrar dias bloqueados na agenda',
          type: 'switch',
        },
      },
      diasAtendimento: {
        domingo: {
          label: 'Domingo',
          type: 'checkbox',
          layout: {
            sm: 3,
            minWidth: 120,
          },
        },
        segunda: {
          label: 'Segunda',
          type: 'checkbox',
          layout: {
            sm: 3,
            minWidth: 120,
          },
        },
        terca: {
          label: 'Terça',
          type: 'checkbox',
          layout: {
            sm: 3,
            minWidth: 120,
          },
        },
        quarta: {
          label: 'Quarta',
          type: 'checkbox',
          layout: {
            sm: 3,
            minWidth: 120,
          },
        },
        quinta: {
          label: 'Quinta',
          type: 'checkbox',
          layout: {
            sm: 3,
            minWidth: 120,
          },
        },
        sexta: {
          label: 'Sexta',
          type: 'checkbox',
          layout: {
            sm: 3,
            minWidth: 120,
          },
        },
        sabado: {
          label: 'Sábado',
          type: 'checkbox',
          layout: {
            sm: 3,
            minWidth: 120,
          },
        },
      },
      horarioAtendimento: {
        horaInicio: {
          label: 'Início',
          type: 'time',
          layout: {
            sm: 6,
            minWidth: 200,
          },
          validations: {
            required: true,
          },
        },
        horaFim: {
          label: 'Fim',
          type: 'time',
          layout: {
            sm: 6,
            minWidth: 200,
          },
          validations: { required: true },
        },
      },
      horarioAlmoco: {
        horarioAlmoco: {
          label: 'Horário de almoço',
          type: 'switch',
          layout: { minWidth: 200 },
        },
        horaAlmocoInicio: {
          label: 'Início',
          type: 'time',
          hidden: !model.horarioAlmoco.horarioAlmoco,
          layout: {
            sm: 6,
            minWidth: 200,
          },
          validations: {
            required: model.horarioAlmoco.horarioAlmoco,
          },
        },
        horaAlmocoFim: {
          label: 'Fim',
          type: 'time',
          hidden: !model.horarioAlmoco.horarioAlmoco,
          layout: {
            sm: 6,
            minWidth: 200,
          },
          validations: {
            required: model.horarioAlmoco.horarioAlmoco,
          },
        },
      },
      carencia: {
        alertaPeriodoCarencia: {
          label: 'Alerta de período de carência',
          type: 'switch',
        },
      },
      outrasInformacoes: {
        observacao: {
          label: 'Observação',
          type: 'textarea',
        },
      },
    }),
    ctx,
    mapCustomErrors: model => {
      const errors: IFormValidationErrors[] = [];

      const d = model.diasAtendimento;

      if (
        !d.domingo &&
        !d.segunda &&
        !d.terca &&
        !d.quarta &&
        !d.quinta &&
        !d.sexta &&
        !d.sabado
      ) {
        errors.push({
          label: 'Dias de atendimento',
          error: 'selecione ao menos um dia da semana',
        });
      }

      return errors;
    },
    noId: true,
    async loadEditCallback({ setFormModel }) {
      const editValue = await ConfiguracaoAgendaService.get(profissionalId);

      if (editValue) {
        setInitialProcedimentos(
          (editValue.procedimentosCarencias || []).map(v => v.id),
        );

        setFormModel({
          padroes: {
            visualizacaoPadrao: editValue.visualizacaoPadrao,
            procedimentoPadraoId: editValue.procedimentoPadrao.id,
          },
          diasBloqueados: {
            mostrarDiasBloqueados: editValue.mostrarDiasBloqueados,
          },
          diasAtendimento: {
            domingo: editValue.domingo,
            segunda: editValue.segunda,
            terca: editValue.terca,
            quarta: editValue.quarta,
            quinta: editValue.quinta,
            sexta: editValue.sexta,
            sabado: editValue.sabado,
          },
          horarioAtendimento: {
            horaInicio: DateHelpers.formatHour(editValue.horaInicio),
            horaFim: DateHelpers.formatHour(editValue.horaFim),
          },
          horarioAlmoco: {
            horarioAlmoco: editValue.horarioAlmoco,
            horaAlmocoInicio: DateHelpers.formatHour(
              editValue.horaAlmocoInicio,
            ),
            horaAlmocoFim: DateHelpers.formatHour(editValue.horaAlmocoFim),
          },
          carencia: {
            alertaPeriodoCarencia: editValue.alertaPeriodoCarencia,
          },
          procedimentosCarenciasIds: [...$initialProcedimentos.value],
          outrasInformacoes: {
            observacao: editValue.observacao,
          },
        });
      }
    },
  });

  return { $form, $schema, emitSubmit, $initialProcedimentos };
}
