import { TdSwitch } from '@/components/datatable/columns/TdSwitch';
import { DataTable } from '@/components/datatable/DataTable';
import { QueryGraphql } from '@/graphql/query';
import { useQueryConfig } from '@/lib/composables/useQueryConfig';
import { createComponent, watchRun } from '@/lib/vue';
import {
  IConfiguracaoAgendaProcedimentosItem,
  IFormInputSwitch,
  IProcedimentosQuery,
  IProcedimentosQueryVariables,
  IRequireField,
  IDataTableHeader,
} from '@/typings';
import { Ref, SetupContext } from '@vue/composition-api';

interface IJsxProps {
  // model
  value?: string[];
  profissionalId: string;
  initialValue?: string[];
}

type IProps = IRequireField<IJsxProps, 'value'>;

interface IEvents {
  onInput: (value: string[]) => void;
}

export const ConfiguracaoAgendaProcedimentos = createComponent<
  IJsxProps,
  IEvents
>({
  name: 'ConfiguracaoAgendaProcedimentos',
  props: {
    value: { type: Array, required: true },
    initialValue: { type: Array, default: () => [] },
    profissionalId: { type: String, required: true },
  },
  setup(props: IProps, ctx) {
    const headers = useHeaders();

    const {
      $data: $items,
      $loading,
      setData: setItems,
    } = useProcedimentosQuery(props);

    useWatchers({ props, ctx, $items, setItems });

    return () => (
      <DataTable
        headers={headers}
        items={$items.value}
        loading={$loading.value}
      />
    );
  },
});

function useHeaders(): IDataTableHeader<IConfiguracaoAgendaProcedimentosItem>[] {
  const schema: { carencia: IFormInputSwitch } = {
    carencia: {
      label: null,
      type: 'switch',
    },
  };

  return [
    {
      text: 'Carência',
      value: 'carencia',
      slot: ({ header, item }) => [
        <TdSwitch
          header={header}
          input={schema.carencia}
          value={item.carencia}
          onInput={v => (item.carencia = !!v)}
        />,
      ],
      align: 'center',
      sortable: false,
      width: 160,
    },
    {
      text: 'Procedimento',
      value: 'procedimento',
      mapValue: v => v.procedimento,
    },
  ];
}

function useProcedimentosQuery(props: IProps) {
  return useQueryConfig<
    IProcedimentosQuery,
    IConfiguracaoAgendaProcedimentosItem[],
    IProcedimentosQueryVariables
  >({
    query: QueryGraphql.ProcedimentosQuery,
    variables: () => ({ profissionalId: props.profissionalId }),
    mapData: result =>
      result?.procedimentos.nodes.map(v => ({
        procedimentoId: v.id,
        procedimento: v.nome,
        carencia: toCarencia(v.id, props),
      })) || [],
  });
}

function useWatchers({
  props,
  ctx,
  $items,
  setItems,
}: {
  props: IProps;
  ctx: SetupContext;
  $items: Ref<IConfiguracaoAgendaProcedimentosItem[] | null>;
  setItems: (v: IConfiguracaoAgendaProcedimentosItem[] | null) => void;
}) {
  function emitInput(value: string[]) {
    ctx.emit('input', value);
  }

  watchRun(
    $items,
    items => {
      const ids = (items || [])
        .filter(v => v.carencia)
        .map(v => v.procedimentoId);

      emitInput(ids);
    },
    { deep: true },
  );

  watchRun(
    () => props.initialValue,
    () =>
      setItems(
        ($items.value || []).map(v => ({
          ...v,
          carencia: toCarencia(v.procedimentoId, props),
        })),
      ),
  );

  return { emitInput };
}

function toCarencia(procedimentoId: string, props: IProps) {
  return !!props.initialValue?.includes(procedimentoId);
}
