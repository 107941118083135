import { useFormPage } from '@/lib/composables/form/useFormPage';
import { useConfiguracaoProfissionalPage } from '@/lib/composables/page/useConfiguracaoProfissionalPage';
import { useNome } from '@/lib/composables/utils/useNome';
import { ProcedimentoService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { IProcedimentoFormModel } from '@/typings';
import { computed } from '@vue/composition-api';
import { ConfiguracaoProfissionalPage } from '../../components/ConfiguracaoProfissionalPage';
import { ProcedimentoForm } from '../../components/procedimento/ProcedimentoForm';

export default createComponent({
  name: 'ProcedimentoFormPage',
  setup(props, ctx) {
    const { profissionalId } = useConfiguracaoProfissionalPage();

    const { $nome, setNome } = useNome({ ctx });

    const { page, handleSubmit, isEdit } = useFormPage<IProcedimentoFormModel>({
      ctx,
      async submitCallback(model) {
        if (page.id) {
          return ProcedimentoService.update({
            id: page.id,
            profissionalId,
            model,
          });
        } else {
          return ProcedimentoService.create({
            profissionalId,
            model,
          });
        }
      },
    });

    const $title = computed(() => {
      if ($nome.value) {
        return `Procedimento: ${$nome.value}`;
      }
      return isEdit ? 'Editar Procedimento' : 'Novo Procedimento';
    });

    async function handleDelete(id: string) {
      return ProcedimentoService.delete({
        id,
        profissionalId,
      });
    }

    return () => (
      <ConfiguracaoProfissionalPage title={$title.value} form>
        <ProcedimentoForm
          page={page}
          onSubmit={handleSubmit}
          onDelete={handleDelete}
          onNomeChanged={setNome}
        />
      </ConfiguracaoProfissionalPage>
    );
  },
});
