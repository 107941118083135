import { createComponent } from '@/lib/vue';
import { ConfiguracaoProfissionalPage } from '../../components/ConfiguracaoProfissionalPage';

export default createComponent({
  name: 'CompartilhamentoProntuarioPage',
  setup(props, ctx) {
    return () => (
      <ConfiguracaoProfissionalPage title="Compartilhamento de dados" />
    );
  },
});
