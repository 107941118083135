import { FormFields } from '@/components/form/fields/FormFields';
import { MyForm } from '@/components/form/MyForm';
import { PageSection } from '@/components/page/PageSection';
import {
  IFormEvents,
  IFormProps,
  useFormConfig,
} from '@/lib/composables/form/useFormConfig';
import { INomeEvents, useNome } from '@/lib/composables/utils/useNome';
import { useRouteParams } from '@/lib/composables/utils/useRouter';
import { ExameModeloService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { ProntuarioExameItems } from '@/modules/prontuarios/components/forms/prontuarioExamesForm/ProntuarioExameItems';
import { Routes } from '@/routes/routes';
import { redirectError } from '@/routes/utils';
import {
  IExameModeloFormModel,
  IExameModeloGeralModel,
  IFormSchema,
  IFormValidationErrors,
} from '@/typings';
import { SetupContext } from '@vue/composition-api';

interface IProps extends IFormProps {}

interface IEvents extends IFormEvents<IExameModeloGeralModel>, INomeEvents {}

export const ExameModeloForm = createComponent<IProps, IEvents>({
  name: 'ExameModeloForm',
  props: {
    page: { type: Object, required: true },
  },
  setup(props, ctx) {
    const { profissionalId } = useRouteParams();

    const indexRoute =
      Routes.app.configProfissionais(profissionalId).prontuario.exames.index;

    const { $form, $schema, emitSubmit, emitDelete } = useForm(props, ctx);

    useNome({
      ctx,
      watchNome: () => $form.value.model.nome,
    });

    return () => (
      <MyForm
        form={$form.value}
        cancelTo={indexRoute}
        onSubmit={emitSubmit}
        onDelete={emitDelete}
      >
        <PageSection>
          <FormFields
            form={$form.value}
            schema={$schema.value}
            v-model={$form.value.model}
          />
        </PageSection>

        <ProntuarioExameItems
          form={$form.value}
          v-model={$form.value.model.items}
          // onInput={handleItemsInput}
        />
      </MyForm>
    );
  },
});

function useForm(props: IProps, ctx: SetupContext) {
  return useFormConfig<
    IExameModeloFormModel,
    IFormSchema<IExameModeloGeralModel>
  >({
    page: props.page,
    ctx,
    initialValue: {
      nome: null,
      items: [],
    },
    mapSchema: () => ({
      nome: {
        label: 'Nome',
        type: 'text',
        validations: {
          required: true,
        },
      },
    }),
    mapCustomErrors: model => {
      const errors: IFormValidationErrors[] = [];

      const itemLabel = 'Exames e procedimentos';

      if (model.items.some(v => !v.quantidade && !v.codigo)) {
        errors.push({
          label: itemLabel,
          error: 'nome e quantidade são obrigatórios',
        });
      } else if (model.items.some(v => !v.codigo)) {
        errors.push({
          label: itemLabel,
          error: 'nome é obrigatório',
        });
      } else if (model.items.some(v => !v.quantidade)) {
        errors.push({
          label: itemLabel,
          error: 'quantidade é obrigatória',
        });
      }

      return errors;
    },
    async loadEditCallback({ id, setFormModel }) {
      const editValue = await ExameModeloService.getById(id);

      if (!editValue) return redirectError(404);

      setFormModel({
        nome: editValue.nome,
        items: editValue.items || [],
      });
    },
  });
}
