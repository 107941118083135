import { MyPage } from '@/components/page/MyPage';
import { createComponent } from '@/lib/vue';

export const AlertasEmailPage = createComponent({
  name: 'AlertasEmailPage',
  setup(props, ctx) {
    return () => <MyPage title="Email" />;
  },
});

export default AlertasEmailPage;
