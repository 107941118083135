import { ContextSidebar } from '@/components/sidebars/context/ContextSidebar';
import { ContextSidebarMenu } from '@/components/sidebars/context/ContextSidebarMenu';
import { useAuthInfo } from '@/lib/composables/useAuthInfo';
import { useRoute } from '@/lib/composables/utils/useRouter';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { IMenu } from '@/typings';
import { computed, ComputedRef } from '@vue/composition-api';

export default createComponent({
  name: 'ConfiguracaoProfissionaisSidebar',
  props: {
    value: { type: String },
  },
  setup(props, ctx) {
    const { $user } = useAuthInfo();

    const $menus: ComputedRef<IMenu[]> = computed<IMenu[]>(() => {
      const { profissionalId } = useRoute().params;

      const isOtherUser = $user.value?.id !== profissionalId;

      return [
        {
          text: 'Configurações de profissionais',
          header: true,
        },
        {
          text: 'Agenda',
          icon: MyIcons.agenda,
          to: Routes.app.configProfissionais(profissionalId).agenda,
        },
        {
          text: 'Convênios',
          icon: MyIcons.convenio,
          to: Routes.app.configProfissionais(profissionalId).convenios,
        },
        {
          text: 'Procedimentos',
          icon: MyIcons.procedimento,
          to: Routes.app.configProfissionais(profissionalId).procedimentos
            .index,
        },
        {
          text: 'Alertas',
          icon: MyIcons.alertas,
          to: Routes.app.configProfissionais(profissionalId).alertas,
          noExact: true,
        },
        {
          text: 'Prontuário',
          subheader: true,
          hidden: isOtherUser,
        },
        {
          text: 'Seções',
          icon: MyIcons.prontuarioSecao,
          to: Routes.app.configProfissionais(profissionalId).prontuario.secoes
            .index,
          hidden: isOtherUser,
        },
        {
          text: 'Exames',
          icon: MyIcons.exame,
          to: Routes.app.configProfissionais(profissionalId).prontuario.exames
            .index,
          hidden: isOtherUser,
        },
        {
          text: 'Prescrições',
          icon: MyIcons.prescricao,
          to: Routes.app.configProfissionais(profissionalId).prontuario
            .prescricoes.index,
          hidden: isOtherUser,
        },
        {
          text: 'Atestados',
          icon: MyIcons.atestado,
          to: Routes.app.configProfissionais(profissionalId).prontuario
            .atestados.index,
          hidden: isOtherUser,
        },
        {
          text: 'Compartilhamento',
          icon: MyIcons.compartilhar,
          to: Routes.app.configProfissionais(profissionalId).prontuario
            .compartilhamento,
          hidden: isOtherUser,
        },
        {
          text: 'Sistema',
          subheader: true,
        },
        {
          text: 'Impressões',
          icon: MyIcons.imprimir,
          to: Routes.app.configProfissionais(profissionalId).impressao,
          hidden: isOtherUser,
        },
        {
          text: 'Integrações',
          icon: MyIcons.integracao,
          to: Routes.app.configProfissionais(profissionalId).integracoes,
        },
      ];
    });

    return () => (
      <ContextSidebar>
        <ContextSidebarMenu menus={$menus.value} />
      </ContextSidebar>
    );
  },
});
