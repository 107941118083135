import { FormFields } from '@/components/form/fields/FormFields';
import { PageSection } from '@/components/page/PageSection';
import { FormHeader } from '@/components/typography/FormHeader';
import { createComponent } from '@/lib/vue';
import { IConfiguracaoAgendaFormProps } from '../ConfiguracaoAgendaForm';
import { ConfiguracaoAgendaProcedimentos } from '../ConfiguracaoAgendaProcedimentos';

export const ConfiguracaoAgendaFormTabCarencia = createComponent<
  IConfiguracaoAgendaFormProps & {
    initialProcedimentos: string[];
    profissionalId: string;
  }
>({
  name: 'ConfiguracaoAgendaFormTabCarencia',
  props: {
    form: { type: Object, required: true },
    schema: { type: Object, required: true },
    initialProcedimentos: { type: Array, required: true },
    profissionalId: { type: String, required: true },
  },
  setup(props, ctx) {
    return () => (
      <PageSection>
        <FormHeader title="Carência" />

        <FormFields
          form={props.form}
          schema={props.schema.carencia}
          v-model={props.form.model.carencia}
        />

        <ConfiguracaoAgendaProcedimentos
          initialValue={props.initialProcedimentos}
          profissionalId={props.profissionalId}
          v-model={props.form.model.procedimentosCarenciasIds}
        />
      </PageSection>
    );
  },
});
