import { AlertSelectProfissional } from '@/components/alerts/AlertSelectProfissional';
import { useAuthInfo } from '@/lib/composables/useAuthInfo';
import { useRoute, useRouteParams } from '@/lib/composables/utils/useRouter';
import { createComponent } from '@/lib/vue';
import { router } from '@/routes';
import { userIsAdminAccount, userIsAdminClinica } from '@/store/utils/auth';
import { computed } from '@vue/composition-api';

interface IProps {
  select: boolean;
  form: boolean;
}

export const ConfiguracaoSelectProfissional = createComponent<IProps>({
  name: 'ConfiguracaoSelectProfissional',
  props: {
    select: { type: Boolean, default: false },
    form: { type: Boolean, default: false },
  },
  setup(props, ctx) {
    const { profissionalId } = useRouteParams();

    const $select = useSelect(props);

    function handleInput(value: string | null) {
      if (!value || value === profissionalId) return;

      const route = useRoute().fullPath;

      const newRoute = route.replace(profissionalId, value);

      router.push(newRoute);
    }

    return () => (
      <AlertSelectProfissional
        select={!!$select.value}
        form={props.form}
        text="Você está alterando as configurações de:"
        onInput={handleInput}
      />
    );
  },
});

function useSelect(props: IProps) {
  const { $clinica } = useAuthInfo();

  return computed(
    () =>
      props.select &&
      (userIsAdminClinica() || userIsAdminAccount()) &&
      $clinica.value &&
      $clinica.value.profissionais.length > 1,
  );
}
