import { IndexDataTable } from '@/components/datatable/IndexDataTable';
import { useFormPage } from '@/lib/composables/form/useFormPage';
import { getEditValue } from '@/lib/form';
import { DialogHelpers } from '@/lib/helpers/dialogs/dialog.helpers';
import { uuid } from '@/lib/helpers/uuid';
import { createComponent } from '@/lib/vue';
import {
  IFormPage,
  IIndexDataTableActions,
  IIndexDataTableButton,
  IProcedimentoConvenioFormModel,
  IRequireField,
  IDataTableHeader,
} from '@/typings';
import { computed } from '@vue/composition-api';
import { ProcedimentoConvenioForm } from './ProcedimentoConvenioForm';

interface IJsxProps {
  // model
  value?: IProcedimentoConvenioFormModel[];
}

type IProps = IRequireField<IJsxProps, 'value'>;

export const ProcedimentoConvenios = createComponent<IJsxProps>({
  name: 'ProcedimentoConvenios',
  props: {
    value: { type: Array, required: true },
  },
  setup(props: IProps, ctx) {
    const { page, handleSubmit } = useFormPage<IProcedimentoConvenioFormModel>({
      ctx,
      async submitCallback(dialogModel) {
        if (!dialogModel.id) {
          dialogModel.id = uuid();
          dialogModel.new = true;

          emitInput([...props.value, dialogModel]);
        } else {
          emitInput([
            ...props.value.filter(v => v.id !== dialogModel.id),
            dialogModel,
          ]);
        }
      },
    });

    function emitInput(convenios: IProcedimentoConvenioFormModel[]) {
      ctx.emit('input', convenios);
    }

    const { $items, headers } = useComputeds(props);

    const { btn, actions } = useActions({
      props,
      page,
      handleSubmit,
      emitInput,
    });

    return () => (
      <IndexDataTable
        title="Convênios"
        btn={btn}
        headers={headers}
        items={$items.value}
        actions={actions}
      />
    );
  },
});

interface IItem {
  id: string | undefined;
  nome: string | null | undefined;
  valor: number | null;
}

function useComputeds(props: IProps) {
  const $items = computed<IItem[]>(() =>
    props.value.map(v => ({
      id: v.id,
      nome: v.convenio.nome,
      valor: v.convenio.valor,
    })),
  );

  const headers: IDataTableHeader<IItem>[] = [
    {
      text: 'Nome',
      value: 'nome',
      mapValue: v => v.nome,
    },
    {
      text: 'Valor',
      value: 'valor',
      mapValue: v => v.valor,
      width: 150,
      align: 'center',
      valueAlign: 'center',
      money: true,
    },
  ];

  return { $items, headers };
}

function useActions({
  props,
  page,
  handleSubmit,
  emitInput,
}: {
  props: IProps;
  page: IFormPage;
  handleSubmit: (model: IProcedimentoConvenioFormModel) => Promise<void>;
  emitInput: (convenios: IProcedimentoConvenioFormModel[]) => void;
}) {
  function showDialog({
    title,
    initialValue,
  }: {
    title: string;
    initialValue: IProcedimentoConvenioFormModel | null;
  }) {
    return DialogHelpers.system.form({
      title,
      form: ProcedimentoConvenioForm,
      page,
      initialValue,
      onSubmit: handleSubmit,
    });
  }

  function handleNew() {
    return showDialog({
      title: 'Novo convênio',
      initialValue: null,
    });
  }

  function handleEdit(id?: string | null) {
    const initialValue = getEditValue(id, props.value);

    if (!initialValue) return handleNew();

    return showDialog({
      title: 'Editar convênio',
      initialValue,
    });
  }

  function handleRemove(id: string) {
    emitInput(props.value.filter(f => f.id !== id));
  }

  const actions: IIndexDataTableActions = {
    edit: handleEdit,
    remove: handleRemove,
  };

  const btn: IIndexDataTableButton = {
    text: 'Novo Convênio',
    outlined: true,
    onClick: handleNew,
  };

  return { actions, btn };
}
