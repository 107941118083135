import { FormFields } from '@/components/form/fields/FormFields';
import { PageSection } from '@/components/page/PageSection';
import { createComponent } from '@/lib/vue';
import { IProcedimentoFormProps } from '../ProcedimentoForm';

export const ProcedimentoFields = createComponent<IProcedimentoFormProps>({
  name: 'ProcedimentoFields',
  props: {
    form: { type: Object, required: true },
    schema: { type: Object, required: true },
  },
  setup(props, ctx) {
    return () => (
      <PageSection title="Procedimento">
        <FormFields
          slot="fields"
          form={props.form}
          schema={props.schema.procedimento}
          v-model={props.form.model.procedimento}
        />
      </PageSection>
    );
  },
});
