import { IndexDataTable } from '@/components/datatable/IndexDataTable';
import { QueryGraphql } from '@/graphql/query';
import { useConfiguracaoProfissionalPage } from '@/lib/composables/page/useConfiguracaoProfissionalPage';
import { useQueryConfig } from '@/lib/composables/useQueryConfig';
import { PrescricaoModeloService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import {
  IIndexDataTableActions,
  IIndexDataTableButton,
  IPrescricaoModeloFragment,
  IPrescricoesModelosQuery,
  IPrescricoesModelosQueryVariables,
  IDataTableHeader,
} from '@/typings';
import { ConfiguracaoProfissionalPage } from '../../../components/ConfiguracaoProfissionalPage';

export default createComponent({
  name: 'PrescricoesModelosPage',
  setup(props, ctx) {
    const { profissionalId } = useConfiguracaoProfissionalPage();

    const newRoute =
      Routes.app.configProfissionais(profissionalId).prontuario.prescricoes.new;

    const editRouteFn =
      Routes.app.configProfissionais(profissionalId).prontuario.prescricoes
        .edit;

    const btn: IIndexDataTableButton = {
      text: 'Novo Modelo',
      to: newRoute,
    };

    const actions: IIndexDataTableActions = {
      editRoute: editRouteFn,
      removeMany: PrescricaoModeloService.deleteMany,
    };

    const headers: IDataTableHeader<IPrescricaoModeloFragment>[] = [
      {
        text: 'Nome',
        value: 'nome',
        mapValue: v => v.nome,
      },
    ];

    const { $data, $loading } = usePrescricoesModelosQuery(profissionalId);

    return () => (
      <ConfiguracaoProfissionalPage title="Prescrições">
        <IndexDataTable
          title="Modelos de prescrições"
          titleText="Cadastre as prescrições que mais utiliza como modelos, para economizar tempo durante a consulta com o paciente"
          btn={btn}
          headers={headers}
          items={$data.value}
          loading={$loading.value}
          actions={actions}
        />
      </ConfiguracaoProfissionalPage>
    );
  },
});

function usePrescricoesModelosQuery(profissionalId: string) {
  return useQueryConfig<
    IPrescricoesModelosQuery,
    IPrescricaoModeloFragment[],
    IPrescricoesModelosQueryVariables
  >({
    query: QueryGraphql.PrescricoesModelosQuery,
    variables: () => ({ profissionalId }),
    mapData: result => result?.prescricoesModelos.nodes || [],
  });
}
