import { FormFields } from '@/components/form/fields/FormFields';
import { PageSection } from '@/components/page/PageSection';
import { createComponent } from '@/lib/vue';
import { IConfiguracaoAgendaFormProps } from '../ConfiguracaoAgendaForm';

export const ConfiguracaoAgendaFormTabOutrasInformacoes =
  createComponent<IConfiguracaoAgendaFormProps>({
    name: 'ConfiguracaoAgendaFormTabOutrasInformacoes',
    props: {
      form: { type: Object, required: true },
      schema: { type: Object, required: true },
    },
    setup(props, ctx) {
      return () => (
        <PageSection title="Outras informações">
          <FormFields
            slot="fields"
            form={props.form}
            schema={props.schema.outrasInformacoes}
            v-model={props.form.model.outrasInformacoes}
          />
        </PageSection>
      );
    },
  });
