import { FormFields } from '@/components/form/fields/FormFields';
import { MyForm } from '@/components/form/MyForm';
import { PageSection } from '@/components/page/PageSection';
import { IPerfilImagem, PerfilImagem } from '@/components/perfil/PerfilImagem';
import { ExpandTransition } from '@/components/utils/ExpandTransition';
import {
  IFormEvents,
  IFormProps,
  useFormConfig,
} from '@/lib/composables/form/useFormConfig';
import { ConstantsHelper } from '@/lib/constants/helper';
import { ConfiguracaoImpressaoService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import {
  ConfiguracaoImpressaoLogoPosition,
  IConfiguracaoImpressaoFormModel,
  IConfiguracaoImpressaoFormSchema,
  IForm,
} from '@/typings';
import { Ref, SetupContext } from '@vue/composition-api';

interface IProps extends IFormProps {
  profissionalId: string;
}

interface IEvents extends IFormEvents<IConfiguracaoImpressaoFormModel> {}

export const ConfiguracaoImpressaoForm = createComponent<IProps, IEvents>({
  name: 'ConfiguracaoImpressaoForm',
  props: {
    page: { type: Object, required: true },
    profissionalId: { type: String, required: true },
  },
  setup(props, ctx) {
    const { $form, $schema, emitSubmit } = useForm(props, ctx);

    const { handleImagemInput, handleImagemRemove } = useImageEvents($form);

    return () => (
      <MyForm noCancel form={$form.value} onSubmit={emitSubmit}>
        <PageSection title="Cabeçalho">
          <div class="flex flex-col" slot="fields">
            <FormFields
              form={$form.value}
              schema={$schema.value.cabecalho}
              v-model={$form.value.model.cabecalho}
            />

            <ExpandTransition
              hide={
                !$form.value.model.cabecalho.cabecalho ||
                !$form.value.model.cabecalho.logo
              }
            >
              <PerfilImagem
                value={$form.value.model.logoImagem.url}
                name="Logo"
                logo
                onInput={handleImagemInput}
                onRemove={handleImagemRemove}
              />
            </ExpandTransition>
          </div>
        </PageSection>

        <PageSection divider title="Rodapé">
          <FormFields
            slot="fields"
            form={$form.value}
            schema={$schema.value.rodape}
            v-model={$form.value.model.rodape}
          />
        </PageSection>

        <PageSection divider title="Layout">
          <FormFields
            slot="fields"
            form={$form.value}
            schema={$schema.value.layout}
            v-model={$form.value.model.layout}
          />
        </PageSection>

        <PageSection divider title="Prescrições">
          <FormFields
            slot="fields"
            form={$form.value}
            schema={$schema.value.prescricao}
            v-model={$form.value.model.prescricao}
          />
        </PageSection>
      </MyForm>
    );
  },
});

function useForm(props: IProps, ctx: SetupContext) {
  return useFormConfig<
    IConfiguracaoImpressaoFormModel,
    IConfiguracaoImpressaoFormSchema
  >({
    page: props.page,
    initialValue: {
      cabecalho: {
        cabecalho: false,
        logo: false,
        logoPosition: null,
      },
      rodape: {
        rodape: false,
        assinatura: false,
        rodapeTexto: null,
      },
      layout: {
        tamanhoPagina: null,
        tamanhoFonte: null,
        margem: null,
      },
      prescricao: {
        numeracaoAutomatica: false,
      },
      logoImagem: {
        id: null,
        url: null,
      },
    },
    mapSchema: model => ({
      cabecalho: {
        cabecalho: {
          label: 'Cabeçalho',
          type: 'switch',
          layout: {
            input: { maxWidth: 130 },
          },
        },
        logo: {
          label: 'Logo',
          type: 'checkbox',
          hidden: !model.cabecalho.cabecalho,
          layout: {
            maxWidth: 120,
            input: { maxWidth: 80 },
          },
        },
        logoPosition: {
          label: 'Posição do logo',
          type: 'select',
          hidden: !model.cabecalho.cabecalho,
          disabled: !model.cabecalho.logo,
          items: ConstantsHelper.configuracaoImpressaoLogoPositions,
          layout: { width: 260 },
        },
      },
      rodape: {
        rodape: {
          label: 'Rodapé',
          type: 'switch',
          layout: {
            input: { maxWidth: 110 },
          },
        },
        rodapeTexto: {
          label: 'Texto no rodapé',
          type: 'text',
          hidden: !model.rodape.rodape,
        },
        assinatura: {
          label: 'Assinatura',
          type: 'checkbox',
          hidden: !model.rodape.rodape,
          layout: { maxWidth: 140 },
        },
      },
      layout: {
        tamanhoPagina: {
          label: 'Tamanho da página',
          type: 'select',
          items: ConstantsHelper.configuracaoImpressaoTamanhosPaginas,
          layout: { sm: 6 },
        },
        tamanhoFonte: {
          label: 'Tamanho da fonte',
          type: 'select',
          list: ConstantsHelper.configuracaoImpressaoTamanhosFontes,
          layout: { sm: 6 },
        },
        margem: {
          label: 'Margens',
          type: 'select',
          items: ConstantsHelper.configuracaoImpressaoMargens,
          layout: { sm: 6 },
        },
      },
      prescricao: {
        numeracaoAutomatica: {
          label: 'Numeração automática',
          type: 'switch',
          layout: {
            input: { maxWidth: 220 },
          },
        },
      },
    }),
    ctx,
    noId: true,
    async loadEditCallback({ setFormModel }) {
      const editValue = await ConfiguracaoImpressaoService.get(props);

      if (editValue) {
        setFormModel({
          cabecalho: {
            cabecalho: editValue.cabecalho,
            logo:
              editValue.logoPosition !== ConfiguracaoImpressaoLogoPosition.NONE,
            logoPosition: editValue.logoPosition,
          },
          rodape: {
            rodape: editValue.rodape,
            assinatura: editValue.assinatura,
            rodapeTexto: editValue.rodapeTexto,
          },
          layout: {
            tamanhoPagina: editValue.tamanhoPagina,
            tamanhoFonte: editValue.tamanhoFonte.toFixed(0),
            margem: editValue.margem,
          },
          prescricao: {
            numeracaoAutomatica: editValue.numeracaoAutomatica,
          },
          logoImagem: {
            id: editValue.logo?.id || null,
            url: editValue.logo?.thumbnailUrl || editValue.logo?.url || null,
          },
        });
      }
    },
  });
}

function useImageEvents($form: Ref<IForm<IConfiguracaoImpressaoFormModel>>) {
  return {
    async handleImagemInput({ image, upload }: IPerfilImagem) {
      $form.value.model.logoImagem.url = image;

      $form.value.page.submitting = true;

      $form.value.model.logoImagem.id = await upload;

      $form.value.page.submitting = false;
    },

    handleImagemRemove() {
      $form.value.model.logoImagem.id = null;
      $form.value.model.logoImagem.url = null;
    },
  };
}
