import { MyDraggable } from '@/components/data/MyDraggable';
import { PageSection } from '@/components/page/PageSection';
import { FormHeader } from '@/components/typography/FormHeader';
import { useFormPage } from '@/lib/composables/form/useFormPage';
import { getEditValue } from '@/lib/form';
import { DialogHelpers } from '@/lib/helpers/dialogs/dialog.helpers';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { uuid } from '@/lib/helpers/uuid';
import { createComponent } from '@/lib/vue';
import { IFormInputModel, IFormPage, IRequireField } from '@/typings';
import { ProntuarioSecaoInput } from './input/ProntuarioSecaoInput';
import { ProntuarioSecaoInputForm } from './ProntuarioSecaoInputForm';

interface IJsxProps {
  // model
  value?: IFormInputModel[];
}

type IProps = IRequireField<IJsxProps, 'value'>;

interface IEvents {
  onInput: (fields: IFormInputModel[]) => void;
}

export const ProntuarioSecaoFields = createComponent<IJsxProps, IEvents>({
  name: 'ProntuarioSecaoFields',
  props: {
    value: { type: Array, required: true },
  },
  setup(props: IProps, ctx) {
    const { page, handleSubmit } = useFormPage<IFormInputModel>({
      ctx,
      async submitCallback(model) {
        if (!model.id) {
          handleCreate(model);
        } else {
          handleUpdate(model);
        }
      },
    });

    const { handleCreate, handleUpdate, handleNew, handleEdit, handleRemove } =
      useActions({
        props,
        page,
        emitInput,
        handleSubmit,
      });

    function emitInput(fields: IFormInputModel[]) {
      ctx.emit('input', fields);
    }

    return () => (
      <PageSection id="ProntuarioSecaoFields" divider>
        <FormHeader title="Campos da seção" />

        <ActionsRow handleNew={handleNew} />

        <DraggableFields
          value={props.value}
          emitInput={emitInput}
          handleEdit={handleEdit}
          handleRemove={handleRemove}
        />
      </PageSection>
    );
  },
});

export default ProntuarioSecaoFields;

function useActions({
  props,
  page,
  emitInput,
  handleSubmit,
}: {
  props: IProps;
  emitInput: (fields: IFormInputModel[]) => void;
  page: IFormPage;
  handleSubmit: (model: IFormInputModel) => Promise<void>;
}) {
  function showDialog({
    title,
    initialValue,
  }: {
    title: string;
    initialValue: IFormInputModel | null;
  }) {
    return DialogHelpers.system.form({
      title,
      form: ProntuarioSecaoInputForm,
      page,
      initialValue,
      onSubmit: handleSubmit,
    });
  }

  function handleNew() {
    return showDialog({
      title: 'Novo campo',
      initialValue: null,
    });
  }

  return {
    showDialog,
    handleNew,

    handleEdit(id?: string | null) {
      const initialValue = getEditValue(id, props.value);

      if (!initialValue) {
        return handleNew();
      }

      return showDialog({
        title: 'Editar campo',
        initialValue,
      });
    },

    handleRemove(id: string | undefined) {
      emitInput(props.value.filter(v => v.id !== id));
    },

    handleCreate(model: IFormInputModel) {
      model.id = uuid();
      model.new = true;

      emitInput([...props.value, model]);
    },

    handleUpdate(model: IFormInputModel) {
      const fields = [...props.value];
      // use index to keep model in the same array index
      let index = -1;
      fields.some((f, idx) => {
        if (f.id === model.id) {
          index = idx;
          return true;
        }

        return false;
      });

      if (index === -1) {
        return emitInput([...fields.filter(f => f.id !== model.id), model]);
      }

      fields[index] = model;

      return emitInput(fields);
    },
  };
}

const ActionsRow = createComponent({
  name: 'ProntuarioSecaoFieldsActionsRow',
  props: {
    handleNew: { type: Function, required: true },
  },
  setup(props, ctx) {
    return () => (
      <div class="flex justify-center">
        <v-btn outlined color="primary" onClick={props.handleNew}>
          <v-icon left>{MyIcons.prontuarioSecaoNewField}</v-icon>
          Novo Campo
        </v-btn>
      </div>
    );
  },
});

const DraggableFields = createComponent<{
  value?: IFormInputModel[];
  emitInput: (fields: IFormInputModel[]) => any;
  handleEdit: (id?: string | null | undefined) => any;
  handleRemove: (id: string | undefined) => any;
}>({
  name: 'ProntuarioSecaoFieldsDraggableFields',
  props: {
    value: { type: Array },
    emitInput: { type: Function, required: true },
    handleEdit: { type: Function, required: true },
    handleRemove: { type: Function, required: true },
  },
  setup(props, ctx) {
    return () => {
      if (!props.value || !props.value.length) {
        return null;
      }

      return (
        <MyDraggable value={props.value} onInput={props.emitInput}>
          {props.value.map(field => (
            <ProntuarioSecaoInput
              key={field.id}
              input={field}
              class="mt-4"
              onEdit={props.handleEdit}
              onRemove={props.handleRemove}
            />
          ))}
        </MyDraggable>
      );
    };
  },
});
