import { Tabs } from '@/components/utils/Tabs';
import { useValue } from '@/lib/composables';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent } from '@/lib/vue';
import { ITab } from '@/typings';
import { ConfiguracaoProfissionalPage } from '../../components/ConfiguracaoProfissionalPage';
import { AlertasEmailAniversariantesPage } from './AlertasEmailAniversariantesPage';
import { AlertasEmailPage } from './AlertasEmailPage';
import { AlertasSmsPage } from './AlertasSmsPage';

export default createComponent({
  name: 'AlertasPage',
  setup(props, ctx) {
    const [$activeTab] = useValue(0);

    const tabs: ITab[] = [
      {
        id: 'email',
        text: 'Email',
        icon: MyIcons.email,
      },
      {
        id: 'sms',
        text: 'SMS',
        icon: MyIcons.sms,
      },
      {
        id: 'aniversariantes',
        text: 'Email aniversariantes',
        icon: MyIcons.aniversariantes,
      },
    ];

    return () => (
      <ConfiguracaoProfissionalPage title="Alertas" select>
        <Tabs items={tabs} v-model={$activeTab.value}>
          <AlertasEmailPage slot="email" />

          <AlertasSmsPage slot="sms" />

          <AlertasEmailAniversariantesPage slot="aniversariantes" />
        </Tabs>
      </ConfiguracaoProfissionalPage>
    );
  },
});
