import { FormFields } from '@/components/form/fields/FormFields';
import { MyForm } from '@/components/form/MyForm';
import { PageSection } from '@/components/page/PageSection';
import { FormHeader } from '@/components/typography/FormHeader';
import {
  IDialogFormProps,
  IFormEvents,
  useDialogFormConfig,
} from '@/lib/composables/form/useFormConfig';
import { ConstantsHelper } from '@/lib/constants/helper';
import { LookupsConfigs } from '@/lib/form/lookups';
import { createComponent, watchRun } from '@/lib/vue';
import {
  IForm,
  IProcedimentoDespesaFormModel,
  IProcedimentoDespesaFormSchema,
  TissDespesaTipo,
  TissTabela,
} from '@/typings';
import { computed, Ref, SetupContext } from '@vue/composition-api';

interface IProps extends IDialogFormProps<IProcedimentoDespesaFormModel> {}

interface IEvents extends IFormEvents<IProcedimentoDespesaFormModel> {}

export const ProcedimentoDespesaForm = createComponent<IProps, IEvents>({
  name: 'ProcedimentoDespesaForm',
  props: {
    page: { type: Object, required: true },
    initialValue: { type: Object },
  },
  setup(props, ctx) {
    const { $form, $schema, emitSubmit, emitCancel } = useForm(props, ctx);

    useEvents($form);

    return () => (
      <MyForm
        dialog
        form={$form.value}
        onSubmit={emitSubmit}
        onCancel={emitCancel}
      >
        <PageSection>
          <FormHeader title="Despesa" />

          <FormFields
            form={$form.value}
            schema={$schema.value.despesa}
            v-model={$form.value.model.despesa}
          />
        </PageSection>

        <PageSection>
          <FormHeader title="Tiss" />

          <FormFields
            form={$form.value}
            schema={$schema.value.tiss}
            v-model={$form.value.model.tiss}
          />
        </PageSection>
      </MyForm>
    );
  },
});

function useForm(props: IProps, ctx: SetupContext) {
  const defaultValue: IProcedimentoDespesaFormModel = {
    despesa: {
      tipo: null,
      quantidade: null,
      unidade: null,
      valorUnitario: null,
    },
    tiss: {
      tabela: null,
      codigo: null,
      nome: null,
    },
  };

  return useDialogFormConfig<
    IProcedimentoDespesaFormModel,
    IProcedimentoDespesaFormSchema
  >({
    page: props.page,
    ctx,
    initialValue: props.initialValue || defaultValue,
    mapSchema: model => {
      const $tissTabela = computed(() => {
        switch (model.despesa.tipo) {
          case TissDespesaTipo.MEDICAMENTOS:
            return ConstantsHelper.tiss.tabelas.filter(
              f => f.value === TissTabela.TB_20,
            );
          case TissDespesaTipo.MATERIAIS:
          case TissDespesaTipo.OPME:
            return ConstantsHelper.tiss.tabelas.filter(
              f => f.value === TissTabela.TB_19,
            );
          case TissDespesaTipo.GASES_MEDICINAIS:
          case TissDespesaTipo.DIARIAS:
          case TissDespesaTipo.TAXAS_E_ALUGUEIS:
          default:
            return ConstantsHelper.tiss.tabelas;
        }
      });
      const $procedimentoNome = computed(() => {
        switch (model.despesa.tipo) {
          case TissDespesaTipo.MEDICAMENTOS:
            return 'Medicamento';
          case TissDespesaTipo.MATERIAIS:
          case TissDespesaTipo.OPME:
            return 'Material';
          case TissDespesaTipo.GASES_MEDICINAIS:
          case TissDespesaTipo.DIARIAS:
          case TissDespesaTipo.TAXAS_E_ALUGUEIS:
          default:
            return 'Procedimento';
        }
      });
      return {
        despesa: {
          tipo: {
            label: 'Tipo',
            type: 'select',
            items: ConstantsHelper.tiss.despesaTipos,
            validations: { required: true },
          },
          valorUnitario: {
            label: 'Valor unitário',
            type: 'money',
            layout: { xs: 6 },
            validations: { required: true },
          },
          quantidade: {
            label: 'Quantidade',
            type: 'number',
            integer: true,
            layout: { xs: 6 },
            validations: { required: true },
          },
          unidade: {
            label: 'Unidade de medida',
            type: 'select',
            items: ConstantsHelper.tiss.unidadeMedida,
            validations: { required: true },
          },
        },
        tiss: {
          tabela: {
            label: 'Tabela',
            type: 'select',
            items: $tissTabela.value,
            validations: { required: true },
          },
          codigo: {
            label: $procedimentoNome.value,
            type: 'autocomplete',
            itemLabel: 'descricao',
            itemValue: 'codigo',
            lookup: LookupsConfigs.tissProcedimento({
              tabela: model.tiss.tabela,
              codigo: model.tiss.codigo,
              onModelChange(value) {
                model.tiss.nome = value ? value.nome : null;
              },
            }),
            validations: {
              required: true,
            },
          },
        },
      };
    },
  });
}

function useEvents($form: Ref<IForm<IProcedimentoDespesaFormModel>>) {
  watchRun(
    () => $form.value.model.despesa.tipo,
    tipo => {
      switch (tipo) {
        case TissDespesaTipo.MEDICAMENTOS: {
          $form.value.model.tiss.tabela = TissTabela.TB_20;
          return;
        }
        case TissDespesaTipo.MATERIAIS:
        case TissDespesaTipo.OPME: {
          $form.value.model.tiss.tabela = TissTabela.TB_19;
          return;
        }
        case TissDespesaTipo.GASES_MEDICINAIS:
        case TissDespesaTipo.DIARIAS:
        case TissDespesaTipo.TAXAS_E_ALUGUEIS:
        default: {
          $form.value.model.tiss.tabela = null;
          return;
        }
      }
    },
  );
}
