import { FormFields } from '@/components/form/fields/FormFields';
import { PageSection } from '@/components/page/PageSection';
import { Tabs } from '@/components/utils/Tabs';
import { createComponent } from '@/lib/vue';
import { ITab } from '@/typings';
import { ProcedimentoConvenios } from '../ProcedimentoConvenios';
import { ProcedimentoDespesas } from '../ProcedimentoDespesas';
import { IProcedimentoFormProps } from '../ProcedimentoForm';

interface IProps extends IProcedimentoFormProps {
  tabs: ITab[];
  activeTab: number;
  setActiveTab: (v: number) => void;
}

export const ProcedimentoTabs = createComponent<IProps>({
  name: 'ProcedimentoTabs',
  props: {
    form: { type: Object, required: true },
    schema: { type: Object, required: true },
    tabs: { type: Array, required: true },
    activeTab: { type: Number, required: true },
    setActiveTab: { type: Function, required: true },
  },
  setup(props, ctx) {
    return () => (
      <PageSection divider>
        <Tabs
          items={props.tabs}
          value={props.activeTab}
          onInput={props.setActiveTab}
        >
          <ProcedimentoConvenios
            slot="convenios"
            v-model={props.form.model.convenios}
          />

          <ProcedimentoDespesas
            slot="despesas"
            v-model={props.form.model.despesas}
          />

          <PageSection slot="preparo" title="Preparo">
            <FormFields
              slot="fields"
              form={props.form}
              schema={props.schema.preparo}
              v-model={props.form.model.preparo}
            />
          </PageSection>
        </Tabs>
      </PageSection>
    );
  },
});
