import { useFormPage } from '@/lib/composables/form/useFormPage';
import { useConfiguracaoProfissionalPage } from '@/lib/composables/page/useConfiguracaoProfissionalPage';
import { useNome } from '@/lib/composables/utils/useNome';
import { ProntuarioSecaoService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { IProntuarioSecaoFormModel } from '@/typings';
import { computed } from '@vue/composition-api';
import { ConfiguracaoProfissionalPage } from '../../../components/ConfiguracaoProfissionalPage';
import { ProntuarioSecaoForm } from '../../components/secao/ProntuarioSecaoForm';

export default createComponent({
  name: 'ProntuarioSecaoFormPage',
  setup(props, ctx) {
    const { profissionalId } = useConfiguracaoProfissionalPage();

    const { page, handleSubmit, isEdit } =
      useFormPage<IProntuarioSecaoFormModel>({
        ctx,
        async submitCallback(model) {
          if (page.id) {
            return ProntuarioSecaoService.update({
              id: page.id,
              profissionalId,
              model,
            });
          } else {
            return ProntuarioSecaoService.create({
              profissionalId,
              model,
            });
          }
        },
      });

    const { $nome, setNome } = useNome({ ctx });

    const $title = computed(() => {
      if ($nome.value) {
        return `Seção do prontuário: ${$nome.value}`;
      }

      return isEdit ? 'Editar Seção do prontuário' : 'Nova Seção do prontuário';
    });

    async function handleDelete(id: string) {
      return ProntuarioSecaoService.delete({
        id,
        profissionalId,
      });
    }

    return () => (
      <ConfiguracaoProfissionalPage title={$title.value} form>
        <ProntuarioSecaoForm
          page={page}
          onSubmit={handleSubmit}
          onDelete={handleDelete}
          onNomeChanged={setNome}
        />
      </ConfiguracaoProfissionalPage>
    );
  },
});
