import { FormFields } from '@/components/form/fields/FormFields';
import { MyForm } from '@/components/form/MyForm';
import { PageSection } from '@/components/page/PageSection';
import {
  IDialogFormProps,
  IFormEvents,
  useDialogFormConfig,
} from '@/lib/composables/form/useFormConfig';
import { prontuarioSecaoInputTypes } from '@/lib/helpers/models/prontuario/prontuarioSecao';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent } from '@/lib/vue';
import {
  IForm,
  IFormInputModel,
  IFormSchema,
  ProntuarioSecaoFieldType,
} from '@/typings';
import { computed, SetupContext } from '@vue/composition-api';
import { ProntuarioSecaoInputOptionsFields } from './input/ProntuarioSecaoInputOptionsFields';
import { ProntuarioSecaoInputType } from './input/ProntuarioSecaoInputType';

interface IProps extends IDialogFormProps<IFormInputModel> {}

interface IEvents extends IFormEvents<IFormInputModel> {}

export const ProntuarioSecaoInputForm = createComponent<IProps, IEvents>({
  name: 'ProntuarioSecaoInputForm',
  props: {
    page: { type: Object, required: true },
    initialValue: { type: Object },
  },
  setup(props, ctx) {
    const { $form, $schema, emitSubmit, emitCancel } = useForm(props, ctx);

    const $showSelectType = computed(() => $form.value.model.type === null);

    return () => (
      <MyForm
        dialog
        form={$form.value}
        onSubmit={emitSubmit}
        onCancel={emitCancel}
      >
        <v-slide-y-transition mode="out-in">
          {$showSelectType.value ? (
            <ProntuarioSecaoInputType v-model={$form.value.model.type} />
          ) : (
            <FormPart2 form={$form.value} schema={$schema.value} />
          )}
        </v-slide-y-transition>
      </MyForm>
    );
  },
});

const FormPart2 = createComponent<{
  form: IForm<IFormInputModel>;
  schema: IFormSchema<IFormInputModel>;
}>({
  name: 'ProntuarioSecaoInputFormPart2',
  props: {
    form: { type: Object, required: true },
    schema: { type: Object, required: true },
  },
  setup(props, ctx) {
    const $tipo = computed(() => {
      const found = prontuarioSecaoInputTypes.find(
        f => f.type === props.form.model.type,
      );

      return found ? found.title : null;
    });

    function handleRemoveType() {
      props.form.model.type = null;
    }

    function handleOptionsChange(items: string[]) {
      props.form.model.items = items;
    }

    return () => (
      <PageSection>
        <div class="flex items-center py-2">
          <div class="text-subtitle text-coolGray-700">
            <span class="font-bold">Tipo:</span> {$tipo.value}
          </div>

          <v-btn
            outlined
            color="primary"
            class="ml-6"
            onClick={handleRemoveType}
          >
            <v-icon left>{MyIcons.prontuarioSecaoInputTipo}</v-icon>
            Alterar tipo
          </v-btn>
        </div>

        <FormFields
          form={props.form}
          schema={props.schema}
          v-model={props.form.model}
        />

        {showOptionsFields(props.form.model.type) && (
          <ProntuarioSecaoInputOptionsFields
            form={props.form}
            initialValue={props.form.model.items}
            onInput={handleOptionsChange}
          />
        )}
      </PageSection>
    );
  },
});

function useForm(props: IProps, ctx: SetupContext) {
  const defaultValue: IFormInputModel = {
    label: null,
    type: null,
    suffix: null,
    decimalPlaces: null,
    items: null,
  };

  return useDialogFormConfig<IFormInputModel, IFormSchema<IFormInputModel>>({
    page: props.page,
    ctx,
    initialValue: props.initialValue || defaultValue,
    mapSchema: model => {
      const $isNumberField = computed(
        () => model.type === ProntuarioSecaoFieldType.NUMBER,
      );
      return {
        label: {
          label: 'Título',
          type: 'text',
          validations: {
            required: true,
          },
        },
        suffix: {
          label: 'Unidade',
          type: 'text',
          hidden: !$isNumberField.value,
        },
        decimalPlaces: {
          label: 'Casas decimais',
          type: 'number',
          integer: true,
          hidden: !$isNumberField.value,
          validations: {
            lte: 5,
          },
        },
      };
    },
    mapCustomErrors: value => {
      if (
        showOptionsFields(value.type) &&
        (!value.items || value.items.length < 2)
      ) {
        return [
          {
            label: 'Opções',
            error: 'preencha ao menos duas opções',
          },
        ];
      }
      return [];
    },
  });
}

function showOptionsFields(type: ProntuarioSecaoFieldType | null | undefined) {
  return (
    !!type &&
    [
      ProntuarioSecaoFieldType.RADIO,
      ProntuarioSecaoFieldType.SELECT,
      ProntuarioSecaoFieldType.GROUP_CHECKBOX,
    ].includes(type)
  );
}
