import { MyForm } from '@/components/form/MyForm';
import { useValue } from '@/lib/composables';
import {
  IFormEvents,
  IFormProps,
  useFormConfig,
} from '@/lib/composables/form/useFormConfig';
import { INomeEvents, useNome } from '@/lib/composables/utils/useNome';
import { useRouteParams } from '@/lib/composables/utils/useRouter';
import { randomColor } from '@/lib/constants/color';
import { ConstantsHelper } from '@/lib/constants/helper';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { ConfiguracaoAgendaService, ProcedimentoService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { redirectError } from '@/routes/utils';
import {
  IForm,
  IProcedimentoConvenioFormModel,
  IProcedimentoDespesaFormModel,
  IProcedimentoFormModel,
  IProcedimentoFormSchema,
  ITab,
} from '@/typings';
import { computed, SetupContext } from '@vue/composition-api';
import { ProcedimentoFields } from './procedimentoForm/ProcedimentoFields';
import { ProcedimentoTabs } from './procedimentoForm/ProcedimentoTabs';

interface IProps extends IFormProps {}

interface IEvents extends IFormEvents<IProcedimentoFormModel>, INomeEvents {}

export interface IProcedimentoFormProps {
  form: IForm<IProcedimentoFormModel>;
  schema: IProcedimentoFormSchema;
}

export const ProcedimentoForm = createComponent<IProps, IEvents>({
  name: 'ProcedimentoForm',
  props: {
    page: { type: Object, required: true },
  },
  setup(props, ctx) {
    const {
      profissionalId,
      indexRoute,
      $activeTab,
      setActiveTab,
      tabs,
      setProcedimentoPadraoId,
      $noDelete,
    } = useData(props);

    const { $form, $schema, emitDelete, emitSubmit } = useForm(props, ctx);

    useNome({
      ctx,
      watchNome: () => $form.value.model.procedimento.nome,
    });

    useLoadConfiguracao({
      profissionalId,
      setProcedimentoPadraoId,
    });

    return () => (
      <MyForm
        form={$form.value}
        cancelTo={indexRoute}
        noDelete={$noDelete.value}
        onSubmit={emitSubmit}
        onDelete={emitDelete}
      >
        <ProcedimentoFields form={$form.value} schema={$schema.value} />

        <ProcedimentoTabs
          form={$form.value}
          schema={$schema.value}
          tabs={tabs}
          activeTab={$activeTab.value}
          setActiveTab={setActiveTab}
        />
      </MyForm>
    );
  },
});

function useData(props: IProps) {
  const { profissionalId } = useRouteParams();

  const indexRoute =
    Routes.app.configProfissionais(profissionalId).procedimentos.index;

  const [$activeTab, setActiveTab] = useValue(0);

  const tabs: ITab[] = [
    {
      id: 'convenios',
      text: 'Convênios',
      icon: MyIcons.tableLarge,
    },
    {
      id: 'despesas',
      text: 'Despesas',
      icon: MyIcons.layers,
    },
    {
      id: 'preparo',
      text: 'Preparo',
      icon: MyIcons.clipboard,
    },
  ];

  const [$procedimentoPadraoId, setProcedimentoPadraoId] = useValue<
    string | null
  >(null);

  const $noDelete = computed(
    () =>
      !$procedimentoPadraoId.value ||
      props.page.id === $procedimentoPadraoId.value,
  );

  return {
    profissionalId,
    indexRoute,
    $activeTab,
    setActiveTab,
    tabs,
    $procedimentoPadraoId,
    setProcedimentoPadraoId,
    $noDelete,
  };
}

function useForm(props: IProps, ctx: SetupContext) {
  return useFormConfig<IProcedimentoFormModel, IProcedimentoFormSchema>({
    page: props.page,
    initialValue: {
      procedimento: {
        cor: randomColor(),
        nome: null,
        duracaoMinutos: null,
        tipo: null,
        valor: null,
      },
      preparo: {
        preparo: null,
      },
      convenios: [],
      despesas: [],
    },
    mapSchema: () => ({
      procedimento: {
        nome: {
          label: 'Nome',
          type: 'text',
          validations: { required: true },
        },
        tipo: {
          label: 'Tipo',
          type: 'select',
          items: ConstantsHelper.procedimentoTipos,
          validations: { required: true },
          layout: { input: { maxWidth: 340 } },
        },
        duracaoMinutos: {
          label: 'Duração',
          type: 'number',
          suffix: 'minutos',
          prependIcon: MyIcons.timer,
          integer: true,
          layout: { width: 220 },
          validations: {
            required: true,
            gt: 0,
          },
        },
        cor: {
          label: 'Cor',
          type: 'color',
          layout: { width: 180 },
          validations: { required: true },
        },
        valor: {
          label: 'Valor',
          type: 'money',
          layout: { input: { maxWidth: 200 } },
        },
      },
      preparo: {
        preparo: {
          label: null,
          placeholder: 'Informações de preparo para este procedimento...',
          type: 'rich-text',
        },
      },
    }),
    ctx,
    async loadEditCallback({ id, setFormModel }) {
      const editValue = await ProcedimentoService.getById(id);

      if (!editValue) return redirectError(404);

      const convenios: IProcedimentoConvenioFormModel[] = (
        editValue.convenios || []
      ).map(v => ({
        id: v.id,
        convenio: {
          convenioId: v.convenio.id,
          nome: v.convenio.nome,
          valor: v.valor,
        },
        tiss: {
          codigo: v.tissCodigo,
          nome: v.tissNome,
          tabela: v.tissTabela,
        },
      }));

      const despesas: IProcedimentoDespesaFormModel[] = (
        editValue.despesas || []
      ).map(v => ({
        id: v.id,
        despesa: {
          valorUnitario: v.valorUnitario,
          quantidade: v.quantidade,
          tipo: v.tipo,
          unidade: v.unidade,
        },
        tiss: {
          codigo: v.tissCodigo,
          nome: v.tissNome,
          tabela: v.tissTabela,
        },
      }));

      setFormModel({
        procedimento: {
          cor: editValue.cor,
          nome: editValue.nome,
          duracaoMinutos: editValue.duracaoMinutos,
          tipo: editValue.tipo,
          valor: editValue.valor,
        },
        preparo: {
          preparo: editValue.preparo,
        },
        convenios,
        despesas,
      });
    },
  });
}

function useLoadConfiguracao({
  profissionalId,
  setProcedimentoPadraoId,
}: {
  profissionalId: string;
  setProcedimentoPadraoId: (v: string | null) => void;
}) {
  async function loadConfiguracao() {
    const configuracao = await ConfiguracaoAgendaService.get(profissionalId);
    if (configuracao) {
      setProcedimentoPadraoId(configuracao.procedimentoPadrao.id);
    }
  }

  loadConfiguracao();
}
