import { FormFields } from '@/components/form/fields/FormFields';
import { MyForm } from '@/components/form/MyForm';
import { PageSection } from '@/components/page/PageSection';
import {
  IFormEvents,
  IFormProps,
  useFormConfig,
} from '@/lib/composables/form/useFormConfig';
import { INomeEvents, useNome } from '@/lib/composables/utils/useNome';
import { useRouteParams } from '@/lib/composables/utils/useRouter';
import { PrescricaoModeloService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { ProntuarioPrescricaoMedicamentos } from '@/modules/prontuarios/components/forms/prontuarioPrescricoesForm/PrescricaoModeloMedicamentosFields';
import { Routes } from '@/routes/routes';
import { redirectError } from '@/routes/utils';
import {
  IFormSchema,
  IPrescricaoModeloFormModel,
  IPrescricaoModeloGeralModel,
} from '@/typings';
import { SetupContext } from '@vue/composition-api';

interface IProps extends IFormProps {}

interface IEvents
  extends IFormEvents<IPrescricaoModeloFormModel>,
    INomeEvents {}

export const PrescricaoModeloForm = createComponent<IProps, IEvents>({
  name: 'PrescricaoModeloForm',
  props: {
    page: { type: Object, required: true },
  },
  setup(props, ctx) {
    const { profissionalId } = useRouteParams();

    const indexRoute =
      Routes.app.configProfissionais(profissionalId).prontuario.prescricoes
        .index;

    const { $form, $schema, emitSubmit, emitDelete } = useForm(props, ctx);

    useNome({
      ctx,
      watchNome: () => $form.value.model.nome,
    });

    return () => (
      <MyForm
        form={$form.value}
        cancelTo={indexRoute}
        onSubmit={emitSubmit}
        onDelete={emitDelete}
      >
        <PageSection>
          <FormFields
            form={$form.value}
            schema={$schema.value}
            v-model={$form.value.model}
          />
        </PageSection>

        <ProntuarioPrescricaoMedicamentos
          form={$form.value}
          v-model={$form.value.model.medicamentos}
        />
      </MyForm>
    );
  },
});

function useForm(props: IProps, ctx: SetupContext) {
  return useFormConfig<
    IPrescricaoModeloFormModel,
    IFormSchema<IPrescricaoModeloGeralModel>
  >({
    page: props.page,
    ctx,
    initialValue: {
      nome: null,
      medicamentos: [],
    },
    mapSchema: () => {
      return {
        nome: {
          label: 'Nome',
          type: 'text',
          validations: {
            required: true,
          },
        },
      };
    },
    async loadEditCallback({ id, setFormModel }) {
      const editValue = await PrescricaoModeloService.getById(id);

      if (!editValue) return redirectError(404);

      setFormModel({
        nome: editValue.nome,
        medicamentos: (editValue.medicamentos || []).map(v => ({
          ...v,
          hideObs: !v.observacao,
        })),
      });
    },
  });
}
