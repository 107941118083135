import { useFormPage } from '@/lib/composables/form/useFormPage';
import { useConfiguracaoProfissionalPage } from '@/lib/composables/page/useConfiguracaoProfissionalPage';
import { ConfiguracaoImpressaoService } from '@/lib/services/configuracao/configuracaoImpressao.service';
import { createComponent } from '@/lib/vue';
import { IConfiguracaoImpressaoFormModel } from '@/typings';
import { ConfiguracaoImpressaoForm } from '../components/ConfiguracaoImpressaoForm';
import { ConfiguracaoProfissionalPage } from '../components/ConfiguracaoProfissionalPage';

export default createComponent({
  name: 'ConfiguracaoImpressaoPage',
  setup(props, ctx) {
    const { profissionalId } = useConfiguracaoProfissionalPage();

    const { page, handleSubmit } = useFormPage<IConfiguracaoImpressaoFormModel>(
      {
        ctx,
        submitCallback(model) {
          return ConfiguracaoImpressaoService.update({
            profissionalId,
            model,
          });
        },
      },
    );

    return () => (
      <ConfiguracaoProfissionalPage title="Configurações de impressão" form>
        <ConfiguracaoImpressaoForm
          page={page}
          profissionalId={profissionalId}
          onSubmit={handleSubmit}
        />
      </ConfiguracaoProfissionalPage>
    );
  },
});
