import { useFormPage } from '@/lib/composables/form/useFormPage';
import { useConfiguracaoProfissionalPage } from '@/lib/composables/page/useConfiguracaoProfissionalPage';
import { useNome } from '@/lib/composables/utils/useNome';
import { AtestadoModeloService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { IAtestadoModeloModel } from '@/typings';
import { computed, SetupContext } from '@vue/composition-api';
import { ConfiguracaoProfissionalPage } from '../../../components/ConfiguracaoProfissionalPage';
import { AtestadoModeloForm } from '../../components/AtestadoModeloForm';

export default createComponent({
  name: 'AtestadoModeloFormPage',
  setup(props, ctx) {
    const { profissionalId } = useConfiguracaoProfissionalPage();

    const { $title, handleSubmit, page, setNome } = useComputeds({
      ctx,
      profissionalId,
    });

    const { handleDelete } = useEvents(profissionalId);

    return () => (
      <ConfiguracaoProfissionalPage title={$title.value} form>
        <AtestadoModeloForm
          page={page}
          profissionalId={profissionalId}
          onSubmit={handleSubmit}
          onDelete={handleDelete}
          onNomeChanged={setNome}
        />
      </ConfiguracaoProfissionalPage>
    );
  },
});

function useComputeds({
  ctx,
  profissionalId,
}: {
  ctx: SetupContext;
  profissionalId: string;
}) {
  const { $nome, setNome } = useNome({ ctx });

  const { page, handleSubmit, isEdit } = useFormPage<IAtestadoModeloModel>({
    ctx,
    async submitCallback(model) {
      if (page.id) {
        return AtestadoModeloService.update({
          id: page.id,
          profissionalId,
          model,
        });
      } else {
        return AtestadoModeloService.create({
          profissionalId,
          model,
        });
      }
    },
  });

  const $title = computed(() => {
    if ($nome.value) {
      return `Documento e atestado: ${$nome.value}`;
    }

    return isEdit ? 'Editar Documento e atestado' : 'Novo Documento e atestado';
  });

  return {
    $nome,
    setNome,
    page,
    handleSubmit,
    isEdit,
    $title,
  };
}

function useEvents(profissionalId: string) {
  function handleDelete(id: string) {
    return AtestadoModeloService.delete({
      id,
      profissionalId,
    });
  }

  return { handleDelete };
}
