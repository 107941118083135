import { FormFields } from '@/components/form/fields/FormFields';
import { MyForm } from '@/components/form/MyForm';
import { PageSection } from '@/components/page/PageSection';
import {
  IFormEvents,
  IFormProps,
  useFormConfig,
} from '@/lib/composables/form/useFormConfig';
import { INomeEvents, useNome } from '@/lib/composables/utils/useNome';
import { useRouteParams } from '@/lib/composables/utils/useRouter';
import { ProntuarioSecaoService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { redirectError } from '@/routes/utils';
import {
  IFormInputModel,
  IFormSchema,
  IProntuarioSecaoFormModel,
  IProntuarioSecaoModel,
} from '@/typings';
import { SetupContext } from '@vue/composition-api';
import { ProntuarioSecaoFields } from './ProntuarioSecaoFields';

interface IProps extends IFormProps {}

interface IEvents extends IFormEvents<IProntuarioSecaoModel>, INomeEvents {}

export const ProntuarioSecaoForm = createComponent<IProps, IEvents>({
  name: 'ProntuarioSecaoForm',
  props: {
    page: { type: Object, required: true },
  },
  setup(props, ctx) {
    const { profissionalId } = useRouteParams();

    const indexRoute =
      Routes.app.configProfissionais(profissionalId).prontuario.secoes.index;

    const { $form, $schema, emitDelete, emitSubmit } = useForm(props, ctx);

    useNome({
      ctx,
      watchNome: () => $form.value.model.nome,
    });

    return () => (
      <MyForm
        form={$form.value}
        cancelTo={indexRoute}
        onSubmit={emitSubmit}
        onDelete={emitDelete}
      >
        <PageSection>
          <FormFields
            form={$form.value}
            schema={$schema.value}
            v-model={$form.value.model}
          />
        </PageSection>

        <ProntuarioSecaoFields v-model={$form.value.model.fields} />
      </MyForm>
    );
  },
});

function useForm(props: IProps, ctx: SetupContext) {
  return useFormConfig<
    IProntuarioSecaoFormModel,
    IFormSchema<IProntuarioSecaoModel>
  >({
    page: props.page,
    ctx,
    initialValue: {
      nome: null,
      fields: [],
    },
    mapSchema: () => ({
      nome: {
        label: 'Nome',
        type: 'text',
        validations: {
          required: true,
        },
      },
    }),

    async loadEditCallback({ id, setFormModel }) {
      const editValue = await ProntuarioSecaoService.getById(id);

      if (!editValue) return redirectError(404);

      const fields: IFormInputModel[] = (editValue.fields || []).map(v => ({
        id: v.id,
        label: v.label,
        type: v.type,
        suffix: v.suffix,
        decimalPlaces: v.decimalPlaces,
        items: v.items,
      }));

      setFormModel({
        nome: editValue.nome,
        fields,
      });
    },
  });
}
