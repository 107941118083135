import { IndexDataTable } from '@/components/datatable/IndexDataTable';
import { QueryGraphql } from '@/graphql/query';
import { useConfiguracaoProfissionalPage } from '@/lib/composables/page/useConfiguracaoProfissionalPage';
import { useQueryConfig } from '@/lib/composables/useQueryConfig';
import { ExameModeloService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import {
  IExameModeloFragment,
  IExamesModelosQuery,
  IExamesModelosQueryVariables,
  IIndexDataTableActions,
  IIndexDataTableButton,
  IDataTableHeader,
} from '@/typings';
import { ConfiguracaoProfissionalPage } from '../../../components/ConfiguracaoProfissionalPage';

export default createComponent({
  name: 'ExamesModelosPage',
  setup(props, ctx) {
    const { profissionalId } = useConfiguracaoProfissionalPage();

    const { $data, $loading } = useExamesModelosQuery(profissionalId);

    const { btn, headers, actions } = useActions(profissionalId);

    return () => (
      <ConfiguracaoProfissionalPage title="Exames">
        <IndexDataTable
          title="Modelos de solicitação de exames"
          titleText="Cadastre as solicitações de exames e procedimentos que mais utiliza como modelos, para economizar tempo durante a consulta com o paciente"
          btn={btn}
          headers={headers}
          items={$data.value}
          loading={$loading.value}
          actions={actions}
        />
      </ConfiguracaoProfissionalPage>
    );
  },
});

function useActions(profissionalId: string) {
  const newRoute =
    Routes.app.configProfissionais(profissionalId).prontuario.exames.new;

  const editRouteFn =
    Routes.app.configProfissionais(profissionalId).prontuario.exames.edit;

  const btn: IIndexDataTableButton = {
    text: 'Novo Modelo',
    to: newRoute,
  };

  const actions: IIndexDataTableActions = {
    editRoute: editRouteFn,
    removeMany: ExameModeloService.deleteMany,
  };

  const headers: IDataTableHeader<IExameModeloFragment>[] = [
    {
      text: 'Nome',
      value: 'nome',
      mapValue: v => v.nome,
    },
  ];

  return {
    newRoute,
    editRouteFn,
    btn,
    actions,
    headers,
  };
}

function useExamesModelosQuery(profissionalId: string) {
  return useQueryConfig<
    IExamesModelosQuery,
    IExameModeloFragment[],
    IExamesModelosQueryVariables
  >({
    query: QueryGraphql.ExamesModelosQuery,
    variables: () => ({ profissionalId }),
    mapData: result => result?.examesModelos.nodes || [],
  });
}
