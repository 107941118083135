import { Td } from '@/components/datatable/columns/Td';
import { TdInputText } from '@/components/datatable/columns/TdInputText';
import { TdSwitch } from '@/components/datatable/columns/TdSwitch';
import { IndexDataTable } from '@/components/datatable/IndexDataTable';
import { MyForm } from '@/components/form/MyForm';
import { QueryGraphql } from '@/graphql/query';
import { useValue } from '@/lib/composables';
import {
  IFormEvents,
  IFormProps,
  useFormConfig,
} from '@/lib/composables/form/useFormConfig';
import { useQueryConfig } from '@/lib/composables/useQueryConfig';
import { DialogHelpers } from '@/lib/helpers/dialogs/dialog.helpers';
import {
  getConvenioProfissional,
  getConvenioProfissionalCodigoOperadora,
} from '@/lib/helpers/models/convenio';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { truncateStr } from '@/lib/helpers/utils';
import { createComponent, watchRun } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import {
  IConveniosQuery,
  IConveniosQueryVariables,
  IFormInputSwitch,
  IFormInputText,
  IIndexDataTableButton,
  IProfissionalConvenioItem,
  IProfissionalConveniosFormModel,
  IDataTableHeader,
} from '@/typings';
import { computed, SetupContext } from '@vue/composition-api';
import { ProfissionalConveniosPlanos } from './ProfissionalConveniosPlanos';

interface IProps extends IFormProps {
  profissionalId: string;
}

interface IEvents extends IFormEvents<IProfissionalConveniosFormModel> {}

export const ProfissionalConveniosForm = createComponent<IProps, IEvents>({
  name: 'ProfissionalConveniosForm',
  props: {
    page: { type: Object, required: true },
    profissionalId: { type: String, required: true },
  },
  setup(props, ctx) {
    const btn: IIndexDataTableButton = {
      text: 'Novo convênio',
      to: Routes.app.configClinica.convenios.new,
    };

    const { $items, $loading } = useConveniosQuery(props);

    const { $form, setFormModel, emitSubmit, handleVisualizarPlanos } =
      useFormDialog(props, ctx);

    const headers = useHeaders({ handleVisualizarPlanos });

    watchRun(
      $items,
      items => {
        setFormModel({
          convenios: (items || [])
            .filter(v => v.atende)
            .map(({ id, codigoOperadora }) => ({
              convenioId: id,
              codigoOperadora,
            })),
        });
      },
      { deep: true },
    );

    return () => (
      <MyForm noCancel form={$form.value} onSubmit={emitSubmit}>
        <IndexDataTable
          selectable={false}
          btn={btn}
          headers={headers}
          items={$items.value}
          loading={$loading.value}
        />
      </MyForm>
    );
  },
});

function useHeaders({
  handleVisualizarPlanos,
}: Pick<
  ReturnType<typeof useFormDialog>,
  'handleVisualizarPlanos'
>): IDataTableHeader<IProfissionalConvenioItem>[] {
  return [
    {
      text: 'Atende',
      value: 'atende',
      slot: ({ header, item }) => [
        <TdSwitch
          header={header}
          input={getSchema(item).atende}
          value={item.atende}
          onInput={v => (item.atende = !!v)}
        />,
      ],
      align: 'center',
      sortable: false,
      width: 80,
    },
    {
      text: 'Convênio',
      value: 'convenio',
      mapValue: v => v.nome,
    },
    {
      text: 'Código na operadora',
      value: 'codigoOperadora',
      slot: ({ header, item }) => [
        <TdInputText
          header={header}
          input={getSchema(item).codigoOperadora}
          value={item.codigoOperadora}
          onInput={v => (item.codigoOperadora = v)}
          class="my-3"
        />,
      ],
      sortable: false,
    },
    {
      text: 'Planos',
      value: 'planos',
      slot: ({ header, item }) => [
        <Td header={header} item={item}>
          <v-btn
            light
            text
            icon
            aria-label={planosTooltip(item)}
            data-balloon-pos="up"
            disabled={!item.atende}
            onClick={() => handleVisualizarPlanos(item)}
          >
            <v-icon>{MyIcons.tableSearch}</v-icon>
          </v-btn>
        </Td>,
      ],
      sortable: false,
      width: 100,
    },
  ];
}

function useConveniosQuery(props: IProps) {
  const { $data: $items, $loading } = useQueryConfig<
    IConveniosQuery,
    IProfissionalConvenioItem[],
    IConveniosQueryVariables
  >({
    query: QueryGraphql.ConveniosQuery,
    variables: () => {
      return {
        where: { particular: false },
      };
    },
    mapData: result =>
      result?.convenios.nodes.map(v => ({
        id: v.id,
        nome: v.nome,
        codigoOperadora: getConvenioProfissionalCodigoOperadora(
          v,
          props.profissionalId,
        ),
        atende: !!getConvenioProfissional(v, props.profissionalId),
        planos: v.planos || [],
      })) || [],
  });

  return { $items, $loading };
}

function useFormDialog(props: IProps, ctx: SetupContext) {
  const [$convenio, setConvenio] = useValue<IProfissionalConvenioItem | null>(
    null,
  );

  const $dialogTitle = computed(() => {
    const nome = $convenio.value?.nome;

    const title = nome ? `Convênio: ${nome}` : 'Convênio';

    return truncateStr(title, 35);
  });

  function handleVisualizarPlanos(item: IProfissionalConvenioItem) {
    setConvenio(item);

    showDialog();
  }

  function showDialog() {
    return DialogHelpers.system.form({
      title: $dialogTitle.value,
      form: ProfissionalConveniosPlanos,
      page: props.page,
      initialValue: null,
      onSubmit: () => null,
      profissionalId: props.profissionalId,
      convenio: $convenio.value,
    });
  }

  const { $form, setFormModel, emitSubmit } = useFormConfig<
    IProfissionalConveniosFormModel,
    any
  >({
    page: props.page,
    ctx,
    initialValue: {
      convenios: [],
    },
    mapSchema: () => ({}),
  });

  return {
    handleVisualizarPlanos,
    $form,
    setFormModel,
    emitSubmit,
  };
}

function getSchema(item: IProfissionalConvenioItem): {
  atende: IFormInputSwitch;
  codigoOperadora: IFormInputText;
} {
  return {
    atende: {
      label: null,
      type: 'switch',
    },
    codigoOperadora: {
      label: null,
      type: 'text',
      disabled: !item.atende,
      hideDetails: true,
    },
  };
}

function planosTooltip(item: IProfissionalConvenioItem) {
  return item.atende ? 'Visualizar planos' : null;
}
