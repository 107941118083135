import { PageSection } from '@/components/page/PageSection';
import { FormHeader } from '@/components/typography/FormHeader';
import {
  IProntuarioSecaoInputTypeOption,
  prontuarioSecaoInputTypes,
} from '@/lib/helpers/models/prontuario/prontuarioSecao';
import { createComponent } from '@/lib/vue';
import { ProntuarioSecaoFieldType } from '@/typings';
import { SetupContext } from '@vue/composition-api';

interface IProps {
  type?: ProntuarioSecaoFieldType | null;
}

interface IEvents {
  onInput: (type: ProntuarioSecaoFieldType | null) => void;
}

export const ProntuarioSecaoInputType = createComponent<IProps, IEvents>({
  name: 'ProntuarioSecaoInputType',
  props: {
    type: { type: String as any },
  },
  setup(props, ctx) {
    const { handleSelect } = useEvents(ctx);

    return () => (
      <PageSection
        id="ProntuarioSecaoInputType"
        class="overflow-y-auto max-h-60vh"
      >
        <FormHeader title="Selecione o tipo do campo" />

        <div class="flex flex-wrap">
          {prontuarioSecaoInputTypes.map(option => (
            <OptionCard option={option} handleSelect={handleSelect} />
          ))}
        </div>
      </PageSection>
    );
  },
});

export default ProntuarioSecaoInputType;

function useEvents(ctx: SetupContext) {
  function handleSelect(option: IProntuarioSecaoInputTypeOption) {
    emitInput(option.type);
  }

  function emitInput(type: ProntuarioSecaoFieldType | null) {
    ctx.emit('input', type);
  }

  return { handleSelect, emitInput };
}

const OptionCard = createComponent<{
  option: IProntuarioSecaoInputTypeOption;
  handleSelect: (option: IProntuarioSecaoInputTypeOption) => void;
}>({
  name: 'ProntuarioSecaoInputTypeOptionCard',
  props: {
    option: { type: Object, required: true },
    handleSelect: { type: Function, required: true },
  },
  setup(props, ctx) {
    return () => (
      <div class="flex flex-col w-1/4 m-4" key={props.option.type}>
        <v-card
          hover
          outlined
          ripple
          onClick={() => props.handleSelect(props.option)}
          class="fill-height"
        >
          <div class="p-2">
            <img src={props.option.img} alt={props.option.title} width="100" />
          </div>

          <div class="flex items-center justify-center p-2 text-base font-medium text-center">
            {props.option.title}
          </div>
        </v-card>
      </div>
    );
  },
});
