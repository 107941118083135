import { MyForm } from '@/components/form/MyForm';
import {
  IFormEvents,
  IFormProps,
  useDialogFormConfig,
} from '@/lib/composables/form/useFormConfig';
import { ConvenioService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { ConvenioProfissionalPlanos } from '@/modules/configuracoes/clinica/components/convenio/ConvenioProfissionalPlanos';
import {
  IConvenioPlanoProfissionalModel,
  IProfissionalConvenioItem,
} from '@/typings';
import { SetupContext } from '@vue/composition-api';

interface IProps extends IFormProps {
  convenio: IProfissionalConvenioItem | null;
  profissionalId: string;
}

interface IEvents extends IFormEvents<IConvenioPlanoProfissionalModel> {}

export const ProfissionalConveniosPlanos = createComponent<IProps, IEvents>({
  name: 'ProfissionalConveniosPlanos',
  props: {
    page: { type: Object, required: true },
    convenio: { type: Object, required: true },
    profissionalId: { type: String, required: true },
  },
  setup(props, ctx) {
    const { $form, emitCancel, handleSubmit } = useForm(props, ctx);

    return () => (
      <MyForm
        dialog
        form={$form.value}
        onSubmit={handleSubmit}
        onCancel={emitCancel}
      >
        <ConvenioProfissionalPlanos
          planos={props.convenio?.planos}
          v-model={$form.value.model}
        />
      </MyForm>
    );
  },
});

function useForm(props: IProps, ctx: SetupContext) {
  const { $form, emitCancel } = useDialogFormConfig<
    IConvenioPlanoProfissionalModel[],
    any
  >({
    page: props.page,
    ctx,
    initialValue: (props.convenio?.planos || [])
      .filter(p =>
        p.profissionais?.find(f => f.profissional.id === props.profissionalId),
      )
      .map(v => {
        const periodoCarenciaDias = v.profissionais?.find(
          f => f.profissional.id === props.profissionalId,
        )?.periodoCarenciaDias;

        return {
          planoId: v.id,
          periodoCarenciaDias: periodoCarenciaDias || 30,
        };
      }),
    mapSchema: () => ({}),
  });

  async function handleSubmit() {
    if (props.convenio?.planos.length) {
      ConvenioService.updatePlanos({
        profissionalId: props.profissionalId,
        convenioId: props.convenio.id,
        codigoOperadora: props.convenio.codigoOperadora,
        planos: $form.value.model,
      });
    }

    ctx.emit('submit', $form.value.model);
  }

  return { $form, emitCancel, handleSubmit };
}
