import { IndexDataTable } from '@/components/datatable/IndexDataTable';
import { QueryGraphql } from '@/graphql/query';
import { useConfiguracaoProfissionalPage } from '@/lib/composables/page/useConfiguracaoProfissionalPage';
import { useQueryConfig } from '@/lib/composables/useQueryConfig';
import { ProntuarioSecaoService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import {
  IIndexDataTableActions,
  IIndexDataTableButton,
  IProntuarioSecaoFragment,
  IProntuariosSecoesQuery,
  IProntuariosSecoesQueryVariables,
  IDataTableHeader,
  ProntuarioSecaoOrderBy,
} from '@/typings';
import { computed, Ref } from '@vue/composition-api';
import { ConfiguracaoProfissionalPage } from '../../../components/ConfiguracaoProfissionalPage';

export default createComponent({
  name: 'ProntuariosSecoesPage',
  setup(props, ctx) {
    const { profissionalId } = useConfiguracaoProfissionalPage();

    const { btn, headers, actions } = useData(profissionalId);

    const { $data, setData, $loading } =
      useProntuariosSecoesQuery(profissionalId);

    const { handleReorder } = useEvents({ profissionalId, setData });

    const { $sortedData } = useComputeds($data);

    return () => (
      <ConfiguracaoProfissionalPage title="Prontuários">
        <IndexDataTable
          title="Seções"
          titleText="Crie campos e seções para customizar seu prontuário"
          btn={btn}
          headers={headers}
          items={$sortedData.value}
          loading={$loading.value}
          actions={actions}
          draggable
          onReorder={handleReorder}
        />
      </ConfiguracaoProfissionalPage>
    );
  },
});

function useComputeds($data: Ref<IProntuarioSecaoFragment[] | null>) {
  const $sortedData = computed(
    () => $data.value && [...$data.value].sort((a, b) => a.ordem - b.ordem),
  );

  return { $sortedData };
}

function useData(profissionalId: string) {
  const newRoute =
    Routes.app.configProfissionais(profissionalId).prontuario.secoes.new;

  const editRouteFn =
    Routes.app.configProfissionais(profissionalId).prontuario.secoes.edit;

  const btn: IIndexDataTableButton = {
    text: 'Nova Seção',
    to: newRoute,
  };

  const actions: IIndexDataTableActions = {
    editRoute: editRouteFn,
    removeMany: ProntuarioSecaoService.deleteMany,
  };

  const headers: IDataTableHeader<IProntuarioSecaoFragment>[] = [
    {
      text: 'Nome',
      value: 'nome',
      mapValue: v => v.nome,
      sortable: false,
    },
  ];

  return {
    newRoute,
    editRouteFn,
    btn,
    actions,
    headers,
  };
}

function useEvents({
  profissionalId,
  setData,
}: {
  profissionalId: string;
  setData: (v: IProntuarioSecaoFragment[] | null) => void;
}) {
  function handleReorder(items: IProntuarioSecaoFragment[] | null) {
    if (items) {
      setData(items.map((v, idx) => ({ ...v, ordem: idx })));

      ProntuarioSecaoService.updateOrdem({
        data: items.map((v, idx) => ({ id: v.id, ordem: idx })),
        profissionalId,
      });
    }
  }

  return { handleReorder };
}

function useProntuariosSecoesQuery(profissionalId: string) {
  return useQueryConfig<
    IProntuariosSecoesQuery,
    IProntuarioSecaoFragment[],
    IProntuariosSecoesQueryVariables
  >({
    query: QueryGraphql.ProntuariosSecoesQuery,
    variables: () => ({
      profissionalId,
      orderBy: [ProntuarioSecaoOrderBy.ordem_ASC],
    }),
    mapData: result => result?.prontuariosSecoes.nodes || [],
  });
}
