import { Td } from '@/components/datatable/columns/Td';
import { IndexDataTable } from '@/components/datatable/IndexDataTable';
import { ColorTile } from '@/components/utils/ColorTile';
import { QueryGraphql } from '@/graphql/query';
import { useConfiguracaoProfissionalPage } from '@/lib/composables/page/useConfiguracaoProfissionalPage';
import { useQueryConfig } from '@/lib/composables/useQueryConfig';
import { ProcedimentoService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import {
  IDataTableHeader,
  IIndexDataTableActions,
  IIndexDataTableButton,
  IProcedimentoFragment,
  IProcedimentosQuery,
  IProcedimentosQueryVariables,
} from '@/typings';
import { ConfiguracaoProfissionalPage } from '../../components/ConfiguracaoProfissionalPage';

export default createComponent({
  name: 'ProcedimentosPage',
  setup(props, ctx) {
    const headers = useHeaders();

    const { btn, actions, profissionalId } = useData();

    const { $data, $loading } = useProcedimentosQuery(profissionalId);

    return () => (
      <ConfiguracaoProfissionalPage title="Procedimentos" select>
        <IndexDataTable
          btn={btn}
          headers={headers}
          items={$data.value}
          loading={$loading.value}
          actions={actions}
        />
      </ConfiguracaoProfissionalPage>
    );
  },
});

function useData() {
  const { profissionalId } = useConfiguracaoProfissionalPage();

  const newRoute =
    Routes.app.configProfissionais(profissionalId).procedimentos.new;

  const editRouteFn =
    Routes.app.configProfissionais(profissionalId).procedimentos.edit;

  const btn: IIndexDataTableButton = {
    text: 'Novo Procedimento',
    to: newRoute,
  };

  const actions: IIndexDataTableActions = {
    editRoute: editRouteFn,
    removeMany: (ids: string[]) =>
      ProcedimentoService.deleteMany({ ids, profissionalId }),
  };

  return { profissionalId, btn, actions };
}

function useHeaders(): IDataTableHeader<IProcedimentoFragment>[] {
  return [
    {
      text: 'Nome',
      value: 'nome',
      mapValue: v => v.nome,
    },
    {
      text: 'Duração (em minutos)',
      value: 'duracaoMinutos',
      mapValue: v => v.duracaoMinutos,
      align: 'center',
      valueAlign: 'center',
    },
    {
      text: 'Cor',
      value: 'cor',
      slot: ({ header, item }) => [
        <Td header={header} item={item}>
          <ColorTile value={item.cor} />
        </Td>,
      ],
      align: 'center',
      sortable: false,
      width: 24,
    },
  ];
}

function useProcedimentosQuery(profissionalId: string) {
  return useQueryConfig<
    IProcedimentosQuery,
    IProcedimentoFragment[],
    IProcedimentosQueryVariables
  >({
    query: QueryGraphql.ProcedimentosQuery,
    variables: () => {
      return {
        profissionalId,
      };
    },
    mapData: result => result?.procedimentos.nodes || [],
  });
}
