import { useFormPage } from '@/lib/composables/form/useFormPage';
import { useConfiguracaoProfissionalPage } from '@/lib/composables/page/useConfiguracaoProfissionalPage';
import { ConvenioService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { IProfissionalConveniosFormModel } from '@/typings';
import { ConfiguracaoProfissionalPage } from '../components/ConfiguracaoProfissionalPage';
import { ProfissionalConveniosForm } from '../components/convenios/ProfissionalConveniosForm';

export default createComponent({
  name: 'ProfissionalConveniosPage',
  setup(props, ctx) {
    const { profissionalId } = useConfiguracaoProfissionalPage();

    const { page, handleSubmit } = useFormPage({
      ctx,
      async submitCallback({ convenios }: IProfissionalConveniosFormModel) {
        return ConvenioService.updateConvenios({
          profissionalId,
          convenios,
        });
      },
    });

    return () => (
      <ConfiguracaoProfissionalPage title="Convênios" select>
        <ProfissionalConveniosForm
          page={page}
          profissionalId={profissionalId}
          onSubmit={handleSubmit}
        />
      </ConfiguracaoProfissionalPage>
    );
  },
});
