import { IConvenioFragment } from '@/typings';

export const getConvenioProfissional = (
  v: IConvenioFragment,
  profissionalId: string,
) => {
  if (!v.profissionais) {
    return null;
  }

  return v.profissionais.find(f => f.profissional.id === profissionalId);
};

export const getConvenioProfissionalCodigoOperadora = (
  v: IConvenioFragment,
  profissionalId: string,
) => {
  const convenioProfissional = getConvenioProfissional(v, profissionalId);

  return convenioProfissional?.codigoOperadora || null;
};
