import { getInputComponent } from '@/lib/form';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent } from '@/lib/vue';
import { IFormInputModel, ProntuarioSecaoFieldType } from '@/typings';
import { computed, Ref } from '@vue/composition-api';

interface IProps {
  input: IFormInputModel;
}

interface IEvents {
  onEdit: (id: string | undefined) => void;
  onRemove: (id: string | undefined) => void;
}

export const ProntuarioSecaoInput = createComponent<IProps, IEvents>({
  name: 'ProntuarioSecaoInput',
  props: {
    input: { type: Object, required: true },
  },
  setup(props, ctx) {
    const $inputStyle = computed(() => {
      const isRichText =
        props.input.type === ProntuarioSecaoFieldType.RICH_TEXT;

      const maxWidth = isRichText ? 800 : 500;

      return { 'max-width': `${maxWidth}px` };
    });

    function emitEdit() {
      ctx.emit('edit', props.input.id);
    }

    function emitRemove() {
      ctx.emit('remove', props.input.id);
    }

    return () => (
      <div id="ProntuarioSecaoInput" class="flex items-center">
        <v-icon x-large class="drag-icon">
          {MyIcons.drag}
        </v-icon>

        <v-hover
          scopedSlots={{
            default: inputCard({ props, $inputStyle, emitEdit, emitRemove }),
          }}
        />
      </div>
    );
  },
});

export default ProntuarioSecaoInput;

const inputCard =
  ({
    props,
    $inputStyle,
    emitEdit,
    emitRemove,
  }: {
    props: IProps;
    $inputStyle: Ref<{ 'max-width': string }>;
    emitEdit: () => void;
    emitRemove: () => void;
  }) =>
  ({ hover }: { hover: boolean }) =>
    (
      <v-sheet class="relative input-card">
        <div style={$inputStyle.value} class="flex p-4">
          {inputComponent(props.input)}
        </div>

        {overlay({ hover, emitEdit, emitRemove })}
      </v-sheet>
    );

const inputComponent = (input: IFormInputModel) => {
  const FormInput = getInputComponent(input) as any;

  if (!FormInput) {
    return null;
  }

  return <FormInput input={{ ...input, hideDetails: true }} />;
};

const overlay = ({
  hover,
  emitEdit,
  emitRemove,
}: {
  hover: boolean;
  emitEdit: () => void;
  emitRemove: () => void;
}) => (
  <v-fade-transition>
    <v-overlay value={hover} absolute opacity={0.3} z-index={10}>
      <div class="flex items-center justify-center">
        <v-btn light class="mr-4" onClick={emitEdit}>
          <v-icon left>{MyIcons.edit}</v-icon>
          Editar
        </v-btn>

        <v-btn light onClick={emitRemove}>
          <v-icon left>{MyIcons.remove}</v-icon>
          Remover
        </v-btn>
      </div>
    </v-overlay>
  </v-fade-transition>
);
