import { FormFields } from '@/components/form/fields/FormFields';
import { MyForm } from '@/components/form/MyForm';
import { PageSection } from '@/components/page/PageSection';
import {
  IFormEvents,
  IFormProps,
  useFormConfig,
} from '@/lib/composables/form/useFormConfig';
import { INomeEvents, useNome } from '@/lib/composables/utils/useNome';
import { useRouteParams } from '@/lib/composables/utils/useRouter';
import { AtestadoModeloService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { redirectError } from '@/routes/utils';
import { IAtestadoModeloModel, IFormSchema } from '@/typings';
import { SetupContext } from '@vue/composition-api';

interface IProps extends IFormProps {
  profissionalId: string;
}

interface IEvents extends IFormEvents<IAtestadoModeloModel>, INomeEvents {}

export const AtestadoModeloForm = createComponent<IProps, IEvents>({
  name: 'AtestadoModeloForm',
  props: {
    page: { type: Object, required: true },
    profissionalId: { type: String, required: true },
  },
  setup(props, ctx) {
    const { profissionalId } = useRouteParams();

    const indexRoute =
      Routes.app.configProfissionais(profissionalId).prontuario.atestados.index;

    const { $form, $schema, emitSubmit, emitDelete } = useForm(props, ctx);

    useNome({
      ctx,
      watchNome: () => $form.value.model.nome,
    });

    return () => (
      <MyForm
        form={$form.value}
        cancelTo={indexRoute}
        onSubmit={emitSubmit}
        onDelete={emitDelete}
      >
        <PageSection>
          <FormFields
            form={$form.value}
            schema={$schema.value}
            v-model={$form.value.model}
          />

          <span class="mx-6 my-2 text-body-sm text-coolGray-400">
            * Caso o paciente não possua essas informações cadastradas, esses
            campos irão ficar em branco
          </span>
        </PageSection>
      </MyForm>
    );
  },
});

function useForm(props: IProps, ctx: SetupContext) {
  return useFormConfig<IAtestadoModeloModel, IFormSchema<IAtestadoModeloModel>>(
    {
      page: props.page,
      initialValue: {
        nome: null,
        titulo: null,
        texto: null,
      },
      mapSchema: () => ({
        nome: {
          label: 'Nome',
          type: 'text',
          validations: {
            required: true,
          },
        },
        titulo: {
          label: 'Título',
          type: 'text',
          validations: {
            required: true,
          },
        },
        texto: {
          label: 'Texto',
          type: 'rich-text',
          macros: [
            'nome_paciente',
            'rg_paciente',
            'cpf_paciente',
            'idade_paciente',
            'nome_pai',
            'nome_mae',
            'nome_clinica',
            'data_atual',
            'hora_atual',
            'nome_procedimento',
            'lista_cid',
          ],
          validations: {
            required: true,
          },
        },
      }),
      ctx,
      async loadEditCallback({ id, setFormModel }) {
        const editValue = await AtestadoModeloService.getById(id);

        if (!editValue) return redirectError(404);

        setFormModel({
          nome: editValue.nome,
          titulo: editValue.titulo,
          texto: editValue.texto,
        });
      },
    },
  );
}
