import { useFormPage } from '@/lib/composables/form/useFormPage';
import { useConfiguracaoProfissionalPage } from '@/lib/composables/page/useConfiguracaoProfissionalPage';
import { appMainScrollToTop } from '@/lib/helpers/scroll';
import { ConfiguracaoAgendaService } from '@/lib/services/configuracao/configuracaoAgenda.service';
import { createComponent } from '@/lib/vue';
import { IConfiguracaoAgendaFormModel } from '@/typings';
import { ConfiguracaoAgendaForm } from '../components/agenda/ConfiguracaoAgendaForm';
import { ConfiguracaoProfissionalPage } from '../components/ConfiguracaoProfissionalPage';

export default createComponent({
  name: 'ConfiguracaoAgendaPage',
  setup(props, ctx) {
    const { profissionalId } = useConfiguracaoProfissionalPage();

    const { page, handleSubmit } = useFormPage({
      ctx,
      async submitCallback(model: IConfiguracaoAgendaFormModel) {
        await ConfiguracaoAgendaService.update({
          profissionalId,
          model,
        });

        appMainScrollToTop();
      },
    });

    return () => (
      <ConfiguracaoProfissionalPage title="Configuração da Agenda" select form>
        <ConfiguracaoAgendaForm page={page} onSubmit={handleSubmit} />
      </ConfiguracaoProfissionalPage>
    );
  },
});
