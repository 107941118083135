import { BtnSmall } from '@/components/buttons/BtnSmall';
import { FormFields } from '@/components/form/fields/FormFields';
import { FormHeader } from '@/components/typography/FormHeader';
import { useValue } from '@/lib/composables';
import { uuid } from '@/lib/helpers/uuid';
import { createComponent } from '@/lib/vue';
import { IForm, IFormSchema } from '@/typings';
import { computed, onMounted, Ref, SetupContext } from '@vue/composition-api';

interface IOption {
  id?: string;
  option: string;
}

interface IProps {
  form: IForm<any>;
  initialValue?: string[] | null;
}

interface IEvents {
  onInput: (options: string[]) => void;
}

export const ProntuarioSecaoInputOptionsFields = createComponent<
  IProps,
  IEvents
>({
  name: 'ProntuarioSecaoInputOptionsFields',
  props: {
    form: { type: Object, required: true },
    initialValue: { type: Array, default: () => [] },
  },
  setup(props, ctx) {
    const [$options, setOptions] = useValue<IOption[]>([]);

    const { getInput, $showRemoveBtn, showAddBtn } = useComputeds($options);
    const { handleRemove, emitInput, handleAddClick } = useEvents({
      props,
      ctx,
      $options,
      setOptions,
    });

    return () => (
      <div class="flex flex-col">
        <FormHeader title="Opções" />

        {$options.value.map((option, index) =>
          optionField({
            option,
            index,
            props,
            $showRemoveBtn,
            $options,
            getInput,
            showAddBtn,
            emitInput,
            handleRemove,
            handleAddClick,
          }),
        )}
      </div>
    );
  },
});

function useComputeds($options: Ref<IOption[]>) {
  function getInput(optionNum: number): IFormSchema<IOption> {
    return {
      option: {
        label: `Opção ${optionNum}`,
        type: 'text',
        validations: {
          required: optionNum <= 2,
        },
      },
    };
  }

  const $showRemoveBtn = computed(() => $options.value.length > 2);

  function showAddBtn(index: number) {
    return (
      index + 1 === $options.value.length && !!$options.value[index].option
    );
  }

  return {
    getInput,
    $showRemoveBtn,
    showAddBtn,
  };
}

function useEvents({
  props,
  ctx,
  $options,
  setOptions,
}: {
  props: IProps;
  ctx: SetupContext;
  $options: Ref<IOption[]>;
  setOptions: (v: IOption[]) => void;
}) {
  function handleAdd(option = '') {
    $options.value.push({
      id: uuid(),
      option,
    });

    emitInput();
  }

  function handleRemove(id: string) {
    setOptions($options.value.filter(v => v.id !== id));

    emitInput();
  }

  function emitInput() {
    const options = $options.value.filter(v => v.option).map(v => v.option);

    ctx.emit('input', options);
  }

  const handleAddClick = () => handleAdd();

  onMounted(() => {
    if (props.initialValue?.length) {
      props.initialValue.forEach(option => handleAdd(option));
    } else if ($options.value.length === 0) {
      handleAdd();
      handleAdd();
    }
  });

  return {
    handleAdd,
    handleRemove,
    emitInput,
    handleAddClick,
  };
}

const optionField = ({
  option,
  index,
  props,
  $showRemoveBtn,
  $options,
  getInput,
  showAddBtn,
  emitInput,
  handleRemove,
  handleAddClick,
}: {
  option: IOption;
  index: number;
  props: IProps;
  $showRemoveBtn: Ref<boolean>;
  $options: Ref<IOption[]>;
  getInput: (optionNum: number) => IFormSchema<IOption>;
  showAddBtn: (index: number) => boolean;
  emitInput: () => void;
  handleRemove: (id: string) => void;
  handleAddClick: () => void;
}) => (
  <div key={option.id} class="flex items-center pl-4">
    <FormFields
      form={props.form}
      schema={getInput(index + 1)}
      class="option-field"
      value={$options.value[index]}
      onInput={emitInput}
      maxWidth={300}
    />

    <div class="flex flex-auto mb-4">
      {$showRemoveBtn.value && (
        <BtnSmall
          remove
          tooltip="Remover option"
          onClick={() => handleRemove(option.id!)}
        />
      )}

      {showAddBtn(index) && (
        <BtnSmall add tooltip="Adicionar opção" onClick={handleAddClick} />
      )}
    </div>
  </div>
);
